.view-settings-button {
    position: fixed;
    bottom: 0px;
    left: 47%;
    padding: 8px;
    opacity: 0.75;
    z-index: 10000000;
}

.view-settings-button-mobile {
    position: fixed;
    bottom: 0px;
    left: 125px;
    width: 100px;
    padding: 8px;
    opacity: 0.75;
}
