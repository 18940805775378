/* Gogo Main Style

Table of Contents

00.Variables and Imports
01.Base
02.Buttons
03.Widths-Spacing
04.Borders
05.Badges
06.Breadcrumb
07.Tabs
08.React Siema Carousel
09.Navbar
10.Menu
11.Apps
12.Chat App
13.Survey App
14.Todo App
15.Main
16.Cards
17.Dashboard
18.Calendar
19.Datatable
20.Alerts
21.Forms
22.Slider(Range)
23.Navs
24.Tooltip and popover
25.Progress
26.Rating
27.Sortable
28.Spinner
29.Croppper
30.Modal
31.Authorization
32.Html Editors
33.Icons
34.Floating Label
35.Loading
36.Context Menu
*/

/* 00.Variables and Imports */
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700');

$main-menu-item-height: 110px;
$main-menu-width: 120px;
$main-menu-width-lg: 110px;
$main-menu-width-md: 100px;
$main-menu-width-xs: 90px;

$sub-menu-width: 230px;
$sub-menu-width-lg: 220px;
$sub-menu-width-md: 200px;
$sub-menu-width-xs: 190px;

$navbar-height: 120px;
$navbar-height-xs: 70px;
$navbar-height-md: 80px;
$navbar-height-lg: 90px;

$main-margin: 60px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;

$app-menu-width: 280px;

$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: 0.1rem;

$theme-color-purple: #922c88;
$theme-color-blue: #145388;
$theme-color-green: #576a3d;
$theme-color-orange: #e2863b;
$theme-color-red: #880a1f;

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;

$logo-one-line: '../../img/L2L_ConnectLogo_greenblack.svg';
$company-logo-square: '../../img/L2L-logo-new-medium.png';
$company-logo-large: '../../img/L2L_SMP_logo_White_Green_Background.png';
/* 01.Base */
html {
    width: 100%;
    height: 100%;
    background: $background-color;
}

:root {
    --theme-color-1: #{$theme-color-1};
    --theme-color-2: #{$theme-color-2};
    --theme-color-3: #{$theme-color-3};
    --theme-color-4: #{$theme-color-4};
    --theme-color-5: #{$theme-color-5};
    --theme-color-6: #{$theme-color-6};
    --theme-color-1-10: #{rgba($theme-color-1, 0.1)};
    --theme-color-2-10: #{rgba($theme-color-2, 0.1)};
    --theme-color-3-10: #{rgba($theme-color-3, 0.1)};
    --theme-color-4-10: #{rgba($theme-color-4, 0.1)};
    --theme-color-5-10: #{rgba($theme-color-5, 0.1)};
    --theme-color-6-10: #{rgba($theme-color-6, 0.1)};

    --primary-color: #{$primary-color};
    --foreground-color: #{$foreground-color};
    --separator-color: #{$separator-color};
}

.wadsworth > .form-group {
    margin: 0;
    margin-top: 5;
}

#root {
    height: 100%;
}

body {
    font-family: 'Nunito', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    color: $primary-color;
    background: $background-color;

    &.background {
        height: 100%;

        main {
            margin: 0 !important;
            height: 100%;

            .container {
                height: 100%;
            }
        }
    }
}

@media (min-width: 1440px) {
    .container {
        max-width: 60%;
    }
}

.fixed-background {
    background: url('/assets/img/Login_background.jpg') no-repeat center center
        fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

h1 {
    font-size: 1.75rem;
    padding-bottom: 10px;
    display: inline-block;

    @include respond-below(sm) {
        font-size: 1.3rem;
        padding-top: 0.5rem;
    }
}

h2 {
    font-size: 1.4rem;

    @include respond-below(sm) {
        font-size: 1.1rem;
    }
}

h3 {
    font-size: 1.3rem;

    @include respond-below(sm) {
        font-size: 1rem;
    }
}

h3 {
    font-size: 1.2rem;

    @include respond-below(sm) {
        font-size: 1rem;
    }
}

h4 {
    font-size: 1.15rem;

    @include respond-below(sm) {
        font-size: 0.9rem;
    }
}

h5 {
    font-size: 1.1rem;

    @include respond-below(sm) {
        font-size: 0.9rem;
    }
}

h6 {
    font-size: 1rem;

    @include respond-below(sm) {
        font-size: 0.85rem;
    }
}

hr {
    border-top: 1px solid $separator-color;
}

/*.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}*/

::selection {
    background: lighten($theme-color-3, 20%);
}

::-moz-selection {
    background: lighten($theme-color-3, 20%);
}

.scrollbar-container {
    margin-right: -15px;
    padding-right: 15px;
    position: relative;
}

.ps__rail-y {
    width: 5px;
}

.ps__thumb-y {
    left: 0;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    width: 5px;
    left: 0;
}

.ps__thumb-y {
    background-color: $separator-color;
}

.video-play-icon {
    width: 100%;
    height: 100%;
    position: absolute;

    span {
        position: absolute;
        background: rgba(255, 255, 255, 0.7);
        height: 1.25em;
        border-radius: 0.75em;
        line-height: 0.65em;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.75em;
        width: 2em;
        text-align: center;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 0 8px 12px;
            font-size: 0;
            border-color: transparent transparent transparent $theme-color-1;
        }
    }

    &:hover,
    &:active {
        span {
            background: rgba(255, 255, 255, 0.85);
        }
    }
}

.logo-single {
    width: 100%;
    display: inline-block;
    margin-bottom: 10%;
}

@media (max-width: 1200px) {
    .company-logo {
        width: 80%;
    }
}

.list-item-heading {
    font-size: 1rem;
}

.truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.no-transition {
    transition: none !important;
}

.display-1 {
    @include respond-below(sm) {
        font-size: 2.8rem;
    }
}

.display-2 {
    @include respond-below(sm) {
        font-size: 2.6rem;
    }
}

.display-3 {
    @include respond-below(sm) {
        font-size: 2.4rem;
    }
}

.display-4 {
    @include respond-below(sm) {
        font-size: 2.2rem;
    }
}

.lead {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2rem;

    @include respond-below(sm) {
        font-size: 1.1rem;
        line-height: 1.6rem;
    }
}

a {
    color: $primary-color;
    transition: color $animation-time-short;

    &:hover,
    &:active {
        text-decoration: initial;
        color: $theme-color-1;
    }
}

p {
    font-size: 0.85rem;
    line-height: 1.3rem;
    font-family: 'Nunito', sans-serif;
}

.text-large {
    font-size: 1.9rem !important;
}

.text-one {
    font-size: 1rem !important;
}

.text-xlarge {
    font-size: 2.7rem !important;
}

.text-small {
    font-size: 0.76rem;
    line-height: 0.9rem;
}

.text-white {
    color: $button-text-color !important;
}

.text-extra-small {
    font-size: 0.6rem;
}

.text-default {
    color: $primary-color !important;
}

.text-muted {
    color: $muted-color !important;
}

.text-semi-muted {
    color: $secondary-color !important;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-semibold {
    font-weight: 600;
}

.color-theme-1 {
    color: $theme-color-1;
}

.color-theme-2 {
    color: $theme-color-2;
}

.view-icon {
    font-size: 20px;
    color: $secondary-color;

    &:hover {
        color: $theme-color-1;
    }

    &.s {
        font-size: 18px;
    }
}

#displayOptions {
    a {
        cursor: pointer;
    }

    a.active i {
        color: $theme-color-1;
    }

    button {
        border-color: $secondary-color;
        color: $secondary-color;

        &:hover {
            background-color: $theme-color-1;
            border-color: $theme-color-1;
            color: $button-text-color;
        }
    }

    .btn-outline-dark:not(:disabled):not(.disabled):active,
    .btn-outline-dark:not(:disabled):not(.disabled).active,
    .show > .btn-outline-dark.dropdown-toggle {
        background-color: $theme-color-1;
        border-color: $theme-color-1;
        color: $button-text-color;
    }

    .view-icon {
        svg {
            width: 19px;
        }

        .view-icon-svg {
            fill: $secondary-color;
        }

        &:hover,
        &.active {
            .view-icon-svg {
                fill: $theme-color-1;
            }
        }
    }
}

.text-theme-2 {
    color: $theme-color-2 !important;
}

.text-theme-3 {
    color: $theme-color-3 !important;
}

.text-primary,
.text-theme-1 {
    color: $theme-color-1 !important;
}

.text-secondary {
    color: $primary-color !important;
}

.main-heading {
    border-bottom: 1px solid $separator-color;
}

.separator {
    border-bottom: 1px solid $separator-color;
}

.alert-dismissible .close {
    padding: 0.5rem 1.25rem;
}

.rounded {
    border-radius: 50px !important;
}

.img-thumbnail {
    border-radius: $border-radius;
    padding: 0;
    border: initial;
}

.list-thumbnail {
    border-radius: $border-radius;
    padding: 0;
    border: initial;
    height: auto;
    max-width: unset;
    height: 85px;
    object-fit: cover;
    width: unset !important;

    @include respond-below(md) {
        height: 80px;
    }

    @include respond-below(xs) {
        height: 70px;
    }

    &.responsive {
        @include respond-below(md) {
            width: unset;
            height: 136px;
        }

        @include respond-below(xs) {
            width: 110px !important;
            height: 100%;
        }

        @include respond-below(xxs) {
            width: 90px !important;
            height: 100%;
        }
    }

    &.small {
        height: 60px;
        font-size: 1rem;

        @include respond-below(md) {
            height: 55px;
        }

        @include respond-below(xs) {
            height: 50px;
        }
    }

    &.xsmall {
        height: 40px;
        font-size: 1rem;

        @include respond-below(md) {
            height: 40px;
        }

        @include respond-below(xs) {
            height: 40px;
        }
    }
}

.list-thumbnail-letters {
    width: 85px;
    height: 85px;
    background: $theme-color-1;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
    color: $button-text-color;

    @include respond-below(md) {
        width: 80px;
        height: 80px;
    }

    @include respond-below(xs) {
        width: 70px;
        height: 70px;
    }

    &.small {
        width: 60px;
        height: 60px;
        font-size: 1rem;

        @include respond-below(md) {
            width: 55px;
            height: 55px;
        }

        @include respond-below(xs) {
            width: 50px;
            height: 50px;
        }
    }
}

.white {
    color: $button-text-color !important;
}

/* 02.Buttons */

button {
    color: $primary-color;
    outline: initial !important;
}

.btn-arrow {
    display: inline-block;
    text-align: center;
    border-radius: 30px !important;
    width: 42px;
    height: 42px;
    line-height: 24px;
}

.btn-arrow i {
    font-size: 15px;
    display: inline-block;
    text-align: center;
}

.btn-sm.btn-arrow {
    width: 34px;
    height: 34px;
    line-height: 17px;
}

.btn-sm.btn-arrow i {
    font-size: 13px;
    line-height: 10px;
}

.btn {
    border-radius: 50px;
    outline: initial !important;
    box-shadow: none !important;
    box-shadow: initial !important;
    font-size: 0.8rem;
    padding: 0.75rem 1.3rem 0.6rem 1.3rem;
    transition: background-color box-shadow 0.1s linear;
}

.btn-shadow {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15),
        0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
    transition: background-color box-shadow 0.1s linear;

    &:hover,
    &:focus {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15),
            0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
    }
}

.btn-empty {
    background: transparent !important;
}

.btn-lg,
.btn-group-lg > .btn,
.btn-group-sm > .btn {
    border-radius: 50px;
}

.btn.default {
    border-radius: $border-radius;
}

.btn-primary {
    background-color: $theme-color-1;
    border-color: $theme-color-1;
    color: $button-text-color;

    &:hover {
        color: $button-text-color;
        background-color: darken($theme-color-1, 8%);
        border-color: darken($theme-color-1, 8%);
    }
}

.check-button {
    cursor: default !important;
}

.check-button.btn-primary {
    background-color: $theme-color-1 !important;
    border-color: $theme-color-1 !important;
    opacity: 1;
}

.check-button .custom-control {
    min-height: 1.1rem;
    margin-top: -7px;
}

.dropdown-menu {
    font-size: 0.8rem;
    border-radius: $border-radius;
    background: $input-background;
    border-color: rgba($primary-color, 0.15);
}

.dropdown-item {
    padding: 0.75rem 1.5rem;
    color: $primary-color;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: $background-color;
    color: $primary-color;
}

.dropdown-item.active,
.dropdown-item:active {
    color: $button-text-color;
    text-decoration: none;
    background-color: $theme-color-1;
}

.dropdown-divider {
    border-color: $separator-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    background-color: darken($theme-color-1, 12%);
    border-color: darken($theme-color-1, 12%);
    color: $button-text-color;
}

.btn-secondary {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
    color: $button-text-color;

    &:hover {
        color: $button-text-color;
        background-color: darken($theme-color-2, 8%);
        border-color: darken($theme-color-2, 8%);
    }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    background-color: darken($theme-color-2, 12%);
    border-color: darken($theme-color-2, 12%);
    color: $button-text-color;
}

.btn-primary.btn-primary-gradient {
    background: linear-gradient(
        to right,
        $theme-color-1,
        darken($theme-color-1, 15%)
    );

    &:hover {
        background: linear-gradient(
            to right,
            $theme-color-1,
            darken($theme-color-1, 8%)
        );
    }
}

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-primary-gradient.dropdown-toggle {
    background: linear-gradient(
        to right,
        $theme-color-1,
        darken($theme-color-1, 15%)
    );
}

.btn-secondary-gradient {
    background: linear-gradient(
        to right,
        $theme-color-2,
        darken($theme-color-2, 15%)
    );

    &:hover {
        background: linear-gradient(
            to right,
            $theme-color-2,
            darken($theme-color-2, 8%)
        );
    }
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-secondary-gradient.dropdown-toggle {
    background: linear-gradient(
        to right,
        $theme-color-2,
        darken($theme-color-2, 15%)
    );
}

.btn-warning {
    background-color: $warning-color;
    border-color: $warning-color;
}

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
    color: $button-text-color;

    &:hover {
        color: $button-text-color;
    }
}

.btn-outline-success,
.btn-outline-info,
.btn-outline-danger,
.btn-outline-warning {
    &:hover {
        color: $foreground-color;
    }
}

.btn-light {
    color: $dark-btn-background;
    background-color: $light-btn-background;
    border-color: $light-btn-background;

    &:hover {
        color: $dark-btn-background;
        background-color: darken($light-btn-background, 8%);
        border-color: darken($light-btn-background, 8%);
    }
}

.btn-dark {
    color: $light-btn-background;
    background-color: $dark-btn-background;
    border-color: $dark-btn-background;

    &:hover {
        color: $light-btn-background;
        background-color: darken($dark-btn-background, 5%);
        border-color: darken($dark-btn-background, 5%);
    }
}

.btn-outline-dark {
    color: $dark-btn-background;
    border-color: $dark-btn-background;

    &:hover {
        color: $foreground-color;
        background-color: $dark-btn-background;
        border-color: $dark-btn-background;
    }
}

.btn-outline-white {
    color: $button-text-color;
    border-color: $button-text-color;
    background-color: initial;

    &:hover {
        color: $theme-color-1;
        background-color: $button-text-color;
    }
}

.btn-outline-light {
    color: $light-btn-background;
    border-color: $light-btn-background;

    &:hover {
        color: $foreground-color;
        background-color: $light-btn-background;
        border-color: $light-btn-background;
    }
}

.btn-outline-primary {
    color: $theme-color-1;
    border-color: $theme-color-1;

    &:hover {
        color: $foreground-color;
        background-color: $theme-color-1;
        border-color: $theme-color-1;
    }
}

.btn-outline-theme-3 {
    background: unset;
    color: $theme-color-3;
    border-color: $theme-color-3;

    &:hover {
        background-color: $theme-color-3;
        border-color: $theme-color-3;
        color: $foreground-color;
    }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: $theme-color-1;
    border-color: $theme-color-1;
    color: $foreground-color;
}

.btn-outline-secondary {
    color: $theme-color-2;
    border-color: $theme-color-2;

    &:hover {
        background-color: $theme-color-2;
        border-color: $theme-color-2;
        color: $foreground-color;
    }
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
    color: $foreground-color;
}

.btn-header-light {
    color: $separator-color;
    border-color: transparent;
    background: transparent;

    &:hover {
        background-color: transparent;
        border-color: $separator-color;
    }
}

.btn-header-primary {
    color: $theme-color-1;
    border-color: transparent;
    background: transparent;

    &:hover {
        background-color: transparent;
        border-color: $theme-color-1;
    }
}

.btn-header-secondary {
    color: $theme-color-2;
    border-color: transparent;
    background: transparent;

    &:hover {
        background-color: transparent;
        border-color: $theme-color-2;
    }
}

.btn-header-primary-light {
    color: $theme-color-3;
    border-color: transparent;
    background: transparent;

    &:hover {
        background-color: transparent;
        border-color: $theme-color-3;
    }
}

.btn-xl,
.btn-group-xl > .btn {
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 0.05rem;
    padding: 1rem 3.5rem 0.9rem;
}

.btn-lg,
.btn-group-lg > .btn {
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 0.05rem;
    padding: 0.75rem 2.6rem 0.6rem 2.6rem;
}

.btn-sm,
.btn-group-sm > .btn {
    padding: 0.5rem 1rem;
    font-size: 0.76rem;
    line-height: 1.5;
}

.btn-xs,
.btn-group-xs > .btn {
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1.3;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: $theme-color-1;
    border-color: $theme-color-1;
    color: $foreground-color;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
    color: $foreground-color;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.btn-link {
    color: $theme-color-1;
}

.btn-link:hover {
    color: rgba($theme-color-1, 0.8);
    text-decoration: underline;
}

.white-underline-link {
    color: $button-text-color;
    text-decoration: underline;

    &:hover,
    &:active {
        color: $button-text-color;
        text-decoration: initial;
    }
}

.btn-multiple-state {
    position: relative;
    transition: opacity 500ms;

    .spinner,
    .icon {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        opacity: 0;
        visibility: hidden;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity 500ms;
        color: $button-text-color;
    }

    .icon i {
        vertical-align: text-bottom;
        font-size: 18px;
    }

    .label {
        transition: opacity 500ms;
    }

    &.show-spinner {
        .label {
            opacity: 0;
        }

        .spinner {
            opacity: 1;
            visibility: visible;
        }
    }

    &.show-success {
        .label {
            opacity: 0;
        }

        .icon.success {
            opacity: 1;
            visibility: visible;
        }
    }

    &.show-fail {
        .label {
            opacity: 0;
        }

        .icon.fail {
            opacity: 1;
            visibility: visible;
        }
    }

    &.btn-primary:disabled {
        opacity: 1;
        background: darken($theme-color-1, 10%);
        border-color: darken($theme-color-1, 10%);
    }

    &.btn-secondary:disabled {
        opacity: 1;
        border-color: darken($theme-color-2, 10%);
    }
}

.icon-button {
    padding: 0;
    font-size: 14px;
    width: 34px;
    height: 34px;
    line-height: 34px;

    &.large {
        width: 44px;
        height: 44px;
        font-size: 18px;
    }

    &.small-icon {
        font-size: 12px;
        line-height: 32px;
    }
}

.top-right-button {
    width: calc(100% - 80px);

    @include respond-below(xs) {
        padding-left: 0;
        padding-right: 0;
    }
}

.top-right-button-single {
    width: unset;

    @include respond-below(md) {
        width: calc(100%);
    }
}

/* 03.Widths-Spacing */
.w-10 {
    width: 10% !important;
}

.w-90 {
    width: 90% !important;
}

.w-12 {
    width: 12% !important;
}

.w-88 {
    width: 88% !important;
}

.w-15 {
    width: 15% !important;
}

.w-85 {
    width: 85% !important;
}

.w-20 {
    width: 20% !important;
}

.w-80 {
    width: 80% !important;
}

.w-30 {
    width: 30% !important;
}

.w-70 {
    width: 70% !important;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.w-xs-100 {
    @include respond-below(sm) {
        width: 100% !important;
    }
}

.w-sm-100 {
    @include respond-below(md) {
        width: 100% !important;
    }
}

.r-0 {
    right: 0;
}

.l-0 {
    left: 0;
}

.depth-1 {
    @include depth(1);
}

.depth-2 {
    @include depth(2);
}

.min-width-zero {
    min-width: 0;
}

.mb-5,
.my-5 {
    margin-bottom: 2rem !important;
}

.float-none-xs {
    @include respond-below(xs) {
        float: initial !important;
    }
}

/* 04.Borders */
.border {
    border: 1px solid $separator-color-light !important;
}

.border-right {
    border-right: 1px solid $separator-color-light !important;
}

.border-left {
    border-left: 1px solid $separator-color-light !important;
}

.border-top {
    border-top: 1px solid $separator-color-light !important;
}

.border-bottom {
    border-bottom: 1px solid $separator-color-light !important;
}

.border-primary,
.border-theme-1 {
    border-color: $theme-color-1 !important;
}

.border-theme-2 {
    border-color: $theme-color-2 !important;
}

.border-theme-3 {
    border-color: $theme-color-3 !important;
}

.border-secondary {
    border-color: $primary-color !important;
}

/* 05.Badges */

.bg-theme-2,
.badge-theme-2 {
    background-color: $theme-color-2 !important;
    color: $button-text-color;
}

.bg-theme-3,
.badge-theme-3 {
    background-color: $theme-color-3 !important;
    color: $button-text-color;
}

.bg-primary,
.bg-theme-1,
.badge-primary,
.badge-theme-1 {
    background-color: $theme-color-1 !important;
    color: $button-text-color;
}

.bg-secondary,
.badge-secondary {
    background-color: $theme-color-2 !important;
    color: $button-text-color;
}

.badge-warning {
    background-color: $warning-color;
}

.badge-success {
    background-color: $success-color;
}

.badge-info {
    background-color: $info-color;
}

.badge-danger {
    background-color: $error-color;
}

.badge-success,
.badge-danger,
.badge-warning,
.badge-info {
    color: $button-text-color;
}

.badge {
    padding: 0.55em 0.75em 0.6em 0.75em;
    font-size: 74%;

    &.badge-pill {
        padding-right: 1.25em;
        padding-left: 1.25em;
    }

    &.badge-top-left {
        top: 10px;
        left: -7px;
    }

    &.badge-top-left-2 {
        top: 40px;
        left: -7px;
    }

    &.badge-top-right {
        top: 8px;
        right: -7px;
    }

    &.badge-top-right-2 {
        top: 40px;
        right: -7px;
    }
}

.badge-light {
    background-color: $light-btn-background;
    color: $dark-btn-background;
}

.badge-dark {
    background-color: $dark-btn-background;
    color: $light-btn-background;
}

.badge-outline-primary,
.badge-outline-theme-1 {
    background: unset;
    border: 1px solid $theme-color-1;
    color: $theme-color-1;
}

.badge-outline-secondary,
.badge-outline-theme-2 {
    background: unset;
    border: 1px solid $theme-color-2;
    color: $theme-color-2;
}

.badge-outline-theme-3 {
    background: unset;
    border: 1px solid $theme-color-3;
    color: $theme-color-3;
}

.badge-outline-success {
    background: unset;
    border: 1px solid $success-color;
    color: $success-color;
}

.badge-outline-danger {
    background: unset;
    border: 1px solid $error-color;
    color: $error-color;
}

.badge-outline-warning {
    background: unset;
    border: 1px solid $warning-color;
    color: $warning-color;
}

.badge-outline-info {
    background: unset;
    border: 1px solid $info-color;
    color: $info-color;
}

.badge-outline-light {
    background: unset;
    border: 1px solid $light-btn-background;
    color: $light-btn-background;
}

.badge-outline-dark {
    background: unset;
    border: 1px solid $dark-btn-background;
    color: $dark-btn-background;
}

/* 06.Breadcrumb */
.breadcrumb-container {
    .breadcrumb {
        @include respond-below(md) {
            padding: 0;
        }
    }
}

.breadcrumb {
    background-color: transparent;
    margin-bottom: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: '|';
}

/* 07.Tabs */
.nav-tabs.separator-tabs {
    border-bottom: 1px solid $separator-color;
}

.nav-tabs .nav-link {
    border: initial;
    padding-top: 1rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background: $foreground-color;
}

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
    content: ' ';
    background: $theme-color-1;
    border-radius: 10px;
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0;
    top: 0;
}

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
    content: ' ';
    background: $theme-color-1;
    border-radius: 10px;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    top: unset;
}

.nav-tabs.separator-tabs .nav-link {
    border: initial;
    padding-top: 1rem;
    background: initial;
    padding-left: 0;
    padding-top: 0.5rem;
    padding-right: 0;
    margin-right: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: $secondary-color;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border: initial;
    position: relative;
    color: $theme-color-1;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border: initial;
    color: $theme-color-1;
}

// 08.React Siema Carousel
.react-siema-container {
    overflow: hidden;
    padding-bottom: 15px;
    padding-top: 5px;
}

.slider-nav {
    .left-arrow,
    .right-arrow {
        font-size: 20px;
        color: $theme-color-1;
        display: inline-block;
        vertical-align: middle;
        margin: 0 15px;
        padding-top: 14px;
    }

    .slider-dot-container {
        display: inline-block;
    }

    .btn {
        &:hover,
        &:focus,
        &:active {
            text-decoration: initial;
        }
    }
}

.slider-dot {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background: $separator-color;
    outline: initial !important;
    border: initial;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    &.active {
        background: $theme-color-1;
    }
}

.react-siema-container .card .card-body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.react-siema-container .card .w-50 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

/* 09.Navbar*/
.navbar {
    background: $foreground-color;
    height: $navbar-height;
    padding: 1.5rem $main-margin 1.5rem 0;
    @include depth(1);

    #notificationDropdown {
        width: 260px;
        padding: 1.5rem;
        height: 280px;
        right: 15px;
    }

    #iconMenuDropdown {
        width: 240px;
        padding: 1.5rem;
        height: 280px;
    }

    .icon-menu-item {
        width: 90px;
        display: inline-block;
        text-align: center;
        margin-bottom: 1.7rem;
        color: rgba($primary-color, 0.8);

        i {
            font-size: 28px;
            line-height: 42px;
        }

        span {
            text-align: center;
            padding: 0 10px;
            line-height: 14px;
        }

        &:hover,
        &:focus {
            color: $theme-color-1;
        }
    }

    .menu-button-mobile {
        color: $secondary-color;
        text-align: center;

        svg {
            height: 12px;
            fill: $primary-color;
        }

        @include respond-below(sm) {
            width: 20px;
        }
    }

    .menu-button {
        color: $secondary-color;
        width: $main-menu-width;
        text-align: center;

        svg {
            height: 12px;
        }

        .main {
            fill: $primary-color;
            transition: fill $menu-collapse-time;
        }

        .sub {
            fill: $primary-color;
            transition: fill $menu-collapse-time;
        }

        &:hover {
            color: $theme-color-1;
        }

        @include respond-below(sm) {
            width: 60px;
        }
    }

    .navbar-logo {
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            width: 150px;
        }

        .logo-small {
            width: 50px;
        }
    }

    .navbar-right {
        margin-left: 6rem;

        @include respond-below(md) {
            margin-left: 0;
        }
    }

    .language-button {
        background: $background-color;
        border: initial;
        font-size: 0.8rem;
        color: $secondary-color;
        padding: 0.6rem 1rem;

        @include respond-below(xs) {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
        }
    }

    .search {
        position: relative;
        width: $sub-menu-width;
        border-radius: 20px;
        background: $background-color;

        input {
            border: initial;
            background: transparent;
            outline: initial !important;
            padding: 0.5rem 1rem;
            line-height: 2;
            font-size: 0.8rem;
            width: 93%;
            color: $primary-color;
        }

        .search-icon {
            font-size: 17px;
            border-radius: 10px;
            color: $secondary-color;
            position: absolute;
            width: 40px;
            height: 40px;
            bottom: -8px;
            right: 3px;
            text-align: center;
            cursor: pointer;

            &:hover {
                color: $theme-color-1;
            }
        }

        @include respond-below(md) {
            width: calc($sub-menu-width/2);

            input {
                width: 85%;
            }
        }

        @include respond-below(sm) {
            width: 30px;
            height: 30px;
            background: initial;
            margin-left: 0.6rem;
            color: rgba($primary-color, 0.7);

            input {
                display: none;
            }

            .search-icon {
                font-size: 17px;
                width: 30px;
                height: 30px;
                bottom: -3px;
                right: 0;
                color: inherit;
            }

            &.mobile-view {
                display: block;
                width: 100%;
                position: fixed;
                z-index: 2;
                background: $foreground-color;
                left: 0;
                top: 0;
                height: $navbar-height-xs;
                margin-left: 15px;

                input {
                    display: block;
                    width: 100%;
                    height: 70px;
                    padding-left: 0;
                }

                span {
                    top: 50%;
                    transform: translateY(-50%);
                    right: 25px;
                }
            }
        }
    }

    .header-icons {
        margin-right: 1rem;

        @include respond-below(xs) {
            margin-right: 0;
        }
    }

    .header-icon {
        font-size: 16px;
        color: $secondary-color;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        vertical-align: initial;

        @include respond-below(xs) {
            padding-left: 0.3rem;
            padding-right: 0.3rem;
        }

        &:hover {
            color: $theme-color-1;
        }

        &#fullScreenButton i:last-of-type {
            display: none;
        }

        &.notificationButton {
            .count {
                font-size: 9px;
                color: $theme-color-1;
                border: 1px solid $theme-color-1;
                border-radius: 10px;
                position: absolute;
                width: 18px;
                height: 15px;
                text-align: center;
                font-weight: 700;
                top: 2px;
                right: 2px;
                line-height: 14px;

                @include respond-below(xs) {
                    right: -1px;
                }
            }
        }
    }

    .user {
        color: lighten($primary-color, 30%);
        position: relative;

        img {
            margin-left: 10px;
            border-radius: 30px;
            width: 40px;
        }

        @include respond-below(md) {
            .name {
                display: none;
            }
        }

        @include respond-below(sm) {
            img {
                width: 30px;
            }

            margin-left: initial;

            &:after {
                font-size: 11px;
                width: 14px;
                height: 14px;
                bottom: -3px;
                right: -3px;
            }
        }
    }

    @include respond-below(xl) {
        height: $navbar-height-lg;
    }

    @include respond-below(lg) {
        height: $navbar-height-md;
        padding: 1.5rem $main-margin-md 1.5rem 0;
    }

    @include respond-below(sm) {
        height: $navbar-height-xs;
        padding: $main-margin-xs;
    }
}

#app-container {
    &.sub-hidden .menu-button,
    &.menu-sub-hidden .menu-button {
        .sub {
            fill: $secondary-color;
        }
    }

    &.main-hidden .menu-button,
    &.menu-hidden .menu-button {
        .main,
        .sub {
            fill: $secondary-color;
        }
    }
}

.side-panel {
    top: $navbar-height;
    background-color: #fff;
    position: fixed;
    right: 0;
    width: 75px;
    height: 100%;
    z-index: 100;
    box-shadow: -5px 0px 47px -16px rgba(0, 0, 0, 0.75);

    @include respond-below(xl) {
        top: $navbar-height-lg;
    }

    @include respond-below(lg) {
        top: $navbar-height-md;
    }

    @include respond-below(sm) {
        top: $navbar-height-xs;
    }
}

/* 10.Menu*/
#app-container .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    padding-top: $navbar-height;
    z-index: 4;
    height: calc(100% - #{$navbar-height});

    .scrollbar-container {
        margin-right: 0;
        padding-right: 0;
    }

    .main-menu {
        width: $main-menu-width;
        height: calc(100% - #{$navbar-height});
        background: $foreground-color;
        z-index: 3;
        position: fixed;
        transition: transform $menu-collapse-time;
        padding-top: 2px;
        left: 0;

        .scroll {
            padding-right: unset;
            margin-right: unset;
            height: 100%;

            .ps__thumb-y {
                right: 0;
            }
        }

        &.main-hidden {
            transform: translateX(-$main-menu-width);
        }

        ul li {
            position: relative;

            span {
                text-align: center;
                padding: 0 10px;
                line-height: 14px;
            }

            a {
                height: $main-menu-item-height;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                border-bottom: 1px solid $separator-color-light;
                color: $primary-color;
                transition: color $menu-collapse-time;
                transition: background $menu-collapse-time;

                &:hover,
                &:focus {
                    color: $theme-color-1;
                    background: $background-color;
                }
            }

            i {
                font-size: 32px;
                line-height: 42px;
            }

            &.active a {
                color: $theme-color-1;
            }

            &.active:after {
                content: ' ';
                background: $theme-color-1;
                border-radius: 10px;
                position: absolute;
                width: 6px;
                height: 90px;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
        }

        @include respond-below(xl) {
            width: $main-menu-width-lg;
            height: calc(100% - #{$navbar-height-lg});
        }

        @include respond-below(lg) {
            width: $main-menu-width-md;
            height: calc(100% - #{$navbar-height-md});
        }

        @include respond-below(sm) {
            width: $main-menu-width-xs;
            height: calc(100% - #{$navbar-height-xs});

            ul li i {
                font-size: 28px;
                line-height: 38px;
            }

            ul li a {
                height: $main-menu-item-height-mobile;
            }

            ul li.active:after {
                width: 3px;
                height: 60px;
            }
        }
    }

    .sub-menu {
        width: $sub-menu-width;
        background: $foreground-color;
        z-index: 2;
        position: fixed;
        left: $main-menu-width;
        border-left: 1px solid $separator-color-light;
        @include depth(2);
        transition: transform $menu-collapse-time;
        height: calc(100% - #{$navbar-height});

        .scroll {
            height: 100%;
            margin-top: 2px;
            margin-right: unset;
            padding-right: unset;

            .ps__thumb-y {
                right: 0;
            }
        }

        .ps {
            padding-top: 25px;
            padding-bottom: 25px;
        }

        ul {
            display: none;

            li {
                margin-bottom: 10px;
                margin-left: 30px;

                a {
                    font-size: 13px;
                    display: block;
                    padding: 8px 0;
                }

                i {
                    font-size: 1.3em;
                    margin-right: 10px;
                    color: $secondary-color;
                    vertical-align: middle;
                }

                span {
                    vertical-align: middle;
                    padding-top: 3px;
                    display: inline-block;
                }

                &.active i,
                &.active a {
                    color: $theme-color-1;
                }

                @include respond-below(sm) {
                    margin-left: 15px;
                }
            }
        }

        @include respond-below(xl) {
            left: $main-menu-width-lg;
            width: $sub-menu-width-lg;
            height: calc(100% - #{$navbar-height-lg});
        }

        @include respond-below(lg) {
            left: $main-menu-width-md;
            width: $sub-menu-width-md;
            height: calc(100% - #{$navbar-height-md});
        }

        @include respond-below(sm) {
            left: $main-menu-width-xs;
            width: $sub-menu-width-xs;
            height: calc(100% - #{$navbar-height-xs});
        }
    }

    @include respond-below(xl) {
        padding-top: $navbar-height-lg;
    }

    @include respond-below(lg) {
        padding-top: $navbar-height-md;
    }

    @include respond-below(sm) {
        padding-top: $navbar-height-xs;
        @include depth(1);
    }
}

//Menu Config Main Menu Sub Menu
#app-container {
    &.sub-hidden .sub-menu,
    &.menu-sub-hidden .sub-menu,
    &.menu-hidden .sub-menu {
        transform: translateX(-$sub-menu-width);

        @include respond-below(xl) {
            transform: translateX(-$sub-menu-width-lg);
        }

        @include respond-below(lg) {
            transform: translateX(-$sub-menu-width-md);
        }

        @include respond-below(sm) {
            transform: translateX(-$sub-menu-width-xs);
        }
    }

    &.main-hidden .main-menu,
    &.menu-hidden .main-menu {
        transform: translateX(-$main-menu-width);

        @include respond-below(xl) {
            transform: translateX(-$main-menu-width-lg);
        }

        @include respond-below(lg) {
            transform: translateX(-$main-menu-width-md);
        }

        @include respond-below(sm) {
            transform: translateX(-$main-menu-width-xs);
        }
    }

    &.main-hidden.sub-hidden .sub-menu,
    &.menu-hidden .sub-menu {
        transform: translateX(-$sub-menu-width - $main-menu-width);

        @include respond-below(xl) {
            transform: translateX(-$sub-menu-width-lg - $main-menu-width-lg);
        }

        @include respond-below(lg) {
            transform: translateX(-$sub-menu-width-md - $main-menu-width-md);
        }

        @include respond-below(sm) {
            transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs);
        }
    }

    //Mobile
    &.menu-mobile {
        .main-menu {
            transform: translateX(-$main-menu-width-xs);
        }

        .sub-menu {
            transform: translateX(
                -$sub-menu-width-xs - $main-menu-width-xs - 50
            );
        }
    }

    //Show Temporary
    &.main-show-temporary {
        .main-menu {
            transform: translateX(0);
        }

        .sub-menu {
            transform: translateX(-$sub-menu-width);
        }

        @include respond-below(xl) {
            .main-menu {
                transform: translateX(0);
            }

            .sub-menu {
                transform: translateX(-$sub-menu-width-lg);
            }
        }

        @include respond-below(lg) {
            .main-menu {
                transform: translateX(0);
            }

            .sub-menu {
                transform: translateX(-$sub-menu-width-md);
            }
        }

        @include respond-below(sm) {
            .sub-menu {
                transform: translateX(-$sub-menu-width-xs);
            }
        }
    }

    &.sub-show-temporary,
    &.menu-mobile.sub-show-temporary {
        .sub-menu {
            transform: translateX(0);
        }
    }
}

/* 11.Apps*/
.app-menu {
    z-index: 1;
    @include depth(1);
    width: $app-menu-width;
    float: right;
    background: $foreground-color;
    transition: transform $menu-collapse-time;
    height: calc(100% - #{$navbar-height});
    position: fixed;
    right: 0;
    top: $navbar-height;

    @include respond-below(xl) {
        top: $navbar-height-lg;
        height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
        top: $navbar-height-md;
        transform: translateX($app-menu-width);
        height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
        top: $navbar-height-xs;
        height: calc(100% - #{$navbar-height-xs});
    }

    .scrollbar-container {
        margin-right: unset;
        padding-right: unset;

        .ps__rail-y {
            right: 2px !important;
        }
    }

    &.shown {
        transform: translateX(0);
    }

    .app-menu-button {
        cursor: pointer;
        position: absolute;
        left: -28px;
        background: $foreground-color;
        top: $main-margin - 15;
        padding: 12px 8px 12px 5px;
        border-radius: 0.2rem;
        color: $secondary-color !important;
        box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
        font-size: 15px;
        line-height: 0;

        @include respond-below(xl) {
            top: $main-margin-lg - 14;
        }

        @include respond-below(lg) {
            top: $main-margin-md - 13;
        }

        @include respond-below(sm) {
            top: $main-margin-xs - 2;
        }
    }

    ul {
        li {
            margin-bottom: 5px;

            a {
                font-size: 13px;
                display: block;
                padding: 3px 0;

                &:hover i {
                    color: $theme-color-1;
                }
            }

            i {
                font-size: 1.2em;
                margin-right: 10px;
                color: $secondary-color;
                vertical-align: unset;

                @include respond-below(sm) {
                    font-size: 20px;
                }
            }

            &.active i,
            &.active a {
                color: $theme-color-1;
            }
        }
    }
}

.app-row {
    padding-right: $app-menu-width;

    @include respond-below(lg) {
        padding-right: 0;
    }
}

/* 12.Chat App*/
.chat-app {
    .scrollbar-container {
        padding-left: 10px;
        margin-left: -10px;
        padding-right: 10px;
        margin-right: 0;
        height: calc(100vh - #{$navbar-height} - 270px);

        @include respond-below(xl) {
            height: calc(100vh - #{$navbar-height-lg} - 270px);
        }

        @include respond-below(lg) {
            height: calc(100vh - #{$navbar-height-md} - 270px);
        }

        @include respond-below(sm) {
            height: calc(100vh - #{$navbar-height-xs} - 220px);
        }

        .ps__thumb-y {
            right: 10px;
        }
    }

    .chat-text-left {
        padding-left: 64px;
    }

    .chat-text-right {
        padding-right: 64px;
    }

    .list-item-heading {
        @include respond-below(sm) {
            font-size: 0.9rem;
        }
    }

    .card .card-body {
        @include respond-below(sm) {
            padding: 0.75rem;
        }
    }
}

.chat-app-tab-content {
    height: calc(100% - 45px);
}

.chat-app-tab-pane {
    height: calc(100% - 45px);
}

.chat-input-container {
    width: 100%;
    height: 90px;
    background: $input-background;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    @include depth(1);
    padding-left: $main-menu-width + $sub-menu-width + $main-margin;
    padding-right: $app-menu-width + $main-margin;

    @include respond-below(xl) {
        padding-left: $main-menu-width-lg + $main-margin-lg;
        padding-right: $app-menu-width + $main-margin-lg;
    }

    @include respond-below(lg) {
        padding-left: $main-menu-width-md + $main-margin-md;
        padding-right: $main-margin-md;
    }

    @include respond-below(sm) {
        padding-right: 15px;
        padding-left: 15px;
    }

    input {
        border: initial;
        width: unset;
        height: 90px;
        padding-left: 0;
        display: inline-block;
    }

    .send-button {
        @include respond-below(md) {
            padding-left: 0.7rem;
            padding-right: 0.7rem;
        }
    }
}

/* 13.Survey App*/
.survey-app {
    .answers {
        .badge {
            color: $secondary-color;
            font-size: 13px;
            padding-left: 0.75em;
            padding-right: 0.75em;
        }

        input {
            padding-right: 70px;
        }
    }

    .question {
        &.edit-quesiton {
            .edit-mode {
                display: block;
            }

            .view-mode {
                display: none;
            }

            .view-button {
                display: inline-block;
            }

            .edit-button {
                display: none;
            }
        }

        &.view-quesiton {
            .edit-mode {
                display: none;
            }

            .view-mode {
                display: block;
            }

            .view-button {
                display: none;
            }

            .edit-button {
                display: inline-block;
            }
        }
    }

    .survey-question-types {
        .btn-group-icon {
            font-size: 21px;
            line-height: 28px;
        }

        .btn {
            padding-bottom: 0.3rem;
        }
    }
}

.input-icons {
    position: absolute;
    right: 0;
    top: 9px;
}

/* 14.Todo App*/
.heading-icon {
    font-size: 20px;
    color: $theme-color-1;
    margin-right: 5px;
    line-height: inherit;
    vertical-align: middle;
}

.todo-details {
    margin-top: 20px;
    margin-left: 40px;
}

.heading-number {
    border: 1px solid $theme-color-1;
    padding: 4px;
    vertical-align: middle;
    margin-right: 10px;
    border-radius: 20px;
    width: 34px;
    height: 34px;
    text-align: center;
    color: $theme-color-1;
}

/* 15.Main*/
main {
    margin-left: $sub-menu-width + $main-menu-width + $main-margin;
    margin-top: $navbar-height + $main-margin - 15;
    margin-right: 15px; /*$main-margin;*/
    margin-bottom: $main-margin - 20;
    transition: margin-left $menu-collapse-time;

    &.sub-hidden {
        margin-left: $main-menu-width + $main-margin;
    }

    &.main-hidden {
        margin-left: $main-margin;
    }

    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    @include respond-below(xl) {
        margin-left: $sub-menu-width-lg + $main-menu-width-lg + $main-margin-lg;
        /*margin-right: $main-margin-lg;*/
        margin-top: $main-margin-lg + $navbar-height-lg -10;
        margin-bottom: 30px;
    }

    @include respond-below(lg) {
        margin-left: $sub-menu-width-md + $main-menu-width-md + $main-margin-md;
        /*margin-right: $main-margin-md;*/
        margin-top: $main-margin-md + $navbar-height-md -10;
        margin-bottom: 20px;
    }

    @include respond-below(sm) {
        margin-left: $main-margin-xs !important;
        /*margin-right: $main-margin-xs !important;*/
        margin-top: $main-margin-xs + $navbar-height-xs !important;
        margin-bottom: 0;
    }

    @include respond-below(xs) {
        margin-bottom: 0;
    }
}

//Menu Config Main
#app-container {
    &.sub-hidden main,
    &.menu-sub-hidden main,
    &.menu-hidden main {
        margin-left: $main-menu-width + $main-margin - 40;
    }

    &.main-hidden main,
    &.menu-hidden main {
        margin-left: $main-margin - 40;
    }

    @include respond-below(xl) {
        &.sub-hidden main,
        &.menu-sub-hidden main,
        &.menu-hidden main {
            margin-left: $main-menu-width-lg + $main-margin-lg - 35;
        }

        &.main-hidden main,
        &.menu-hidden main {
            margin-left: $main-margin-lg - 35;
        }
    }

    @include respond-below(lg) {
        &.sub-hidden main,
        &.menu-sub-hidden main,
        &.menu-hidden main {
            margin-left: $main-menu-width-md + $main-margin-md;
        }

        &.main-hidden main,
        &.menu-hidden main {
            margin-left: $main-margin-md;
        }
    }
}

/* 16.Cards*/
.card {
    border: initial;
    background: $foreground-color;

    .card-header .card-icon {
        right: 5px;
        top: 5px;

        i {
            font-size: 12px;
            color: $separator-color;
        }
    }

    .card-subtitle {
        margin: 0;
        margin-bottom: 1rem;
    }

    .card-header .handle {
        cursor: default;
    }

    border-radius: calc(0.15rem - 1px);
    @include depth(1);

    .card-body {
        padding: 1.75rem;

        @include respond-below(xs) {
            padding: 1.25rem;
        }

        &.sm {
            padding: 1.25rem 1.75rem;
        }
    }

    .card-title {
        margin-bottom: 2rem;

        @include respond-below(sm) {
            margin-bottom: 1.25rem;
        }
    }
}

.card-img {
    border-radius: calc(0.25rem - 1px);
    height: 100%;
    object-fit: cover;
    max-height: 200px;
    width: unset;
}

.card-img-fluid {
    border-radius: calc(0.25rem - 1px);
    object-fit: cover;
}

.card-img-bottom {
    width: 100%;
    border-bottom-left-radius: calc(0.15rem - 1px);
    border-bottom-right-radius: calc(0.15rem - 1px);
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.15rem - 1px);
    border-top-right-radius: calc(0.15rem - 1px);
}

.card-img-left {
    width: 100%;
    border-top-left-radius: calc(0.15rem - 1px);
    border-bottom-left-radius: calc(0.15rem - 1px);
    height: 100%;
    object-fit: cover;
}

.card-img-right {
    width: 100%;
    border-top-right-radius: calc(0.15rem - 1px);
    border-bottom-right-radius: calc(0.15rem - 1px);
    height: 100%;
    object-fit: cover;
}

.card-img-overlay {
    background: rgba(#000000, 0.5);
    padding: 1.75rem;

    @include respond-below(xs) {
        padding: 1rem;
    }
}

.card-top-buttons {
    padding: 1.3rem;
    right: 0;
    top: 0;

    @include respond-below(xs) {
        padding: 0.35rem;
    }
}

.card-header {
    border: initial;
    background: initial;
    padding-top: 0;
}

/* 17.Dashboard*/

.dashboard-line-chart {
    height: 283px;
}

.dashboard-quick-post {
    min-height: 263px;
}

.dashboard-list-with-thumbs {
    height: 500px;
}

.dashboard-logs {
    height: 270px;
}

.dashboard-list-with-user {
    height: 270px;
}

.dashboard-donut-chart {
    height: 270px;
}

.dashboard-small-chart {
    height: 150px;

    .chart {
        height: 75px;
    }

    .lead {
        font-size: 1.4rem;
    }
}

.dashboard-small-chart-analytics {
    height: 180px;

    .chart {
        height: 85px;
    }

    .lead {
        font-size: 1.4rem;
    }
}

.dashboard-filled-line-chart {
    height: 340px;

    .chart {
        height: 200px;
    }
}

.dashboard-sq-banner {
    // background: url(/img/sq-banner.jpg);
    // background-image: linear-gradient(to right bottom, $theme-color-1, $theme-color-2);
    background-image: linear-gradient(
        to right top,
        $gradient-color-2,
        $gradient-color-3,
        $gradient-color-1
    );
    background-size: cover;
    height: 385px;
    transition: 0.5s;
    background-size: 350% auto;
    cursor: pointer;

    .card-body {
        width: 270px;
    }

    .lead {
        line-height: 2.3rem;
    }

    &:hover {
        background-position: right top;
    }
}

.dashboard-link-list {
    height: 385px;
}

.dashboard-progress {
    height: 385px;
}

.dashboard-top-rated {
    height: 300px;

    @include respond-below(md) {
        height: unset;
    }

    .react-siema-container {
        margin-right: -0.5rem;
        margin-left: -0.5rem;

        img {
            height: 120px;
            display: flex;
            object-fit: cover;
            width: 100%;
        }

        .react-rater {
            display: inline-block !important;
        }
    }
}

.dashboard-search {
    height: 650px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: $foreground-color;

    .card-body {
        padding: 120px 50px 30px 50px;

        @include respond-below(xl) {
            padding: 80px 30px 30px 30px;
        }
    }

    .form-container {
        height: 400px;
        border-radius: $border-radius;
        box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.04);
        padding: 2rem;
        background-color: $foreground-color;
    }
}

.icon-cards-row {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -10px;

    .react-siema-container {
        padding-bottom: 0px;
        padding-top: 10px;

        .icon-row-item {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    [class*='col-'] {
        padding-left: 5px;
        padding-right: 5px;
    }

    .card-body {
        padding: 2rem 0.5rem;
    }

    .card-text {
        color: $secondary-color;
        height: 30px;
        line-height: 26px;
    }

    .lead {
        color: $theme-color-1;
        margin-bottom: 0;
    }

    i {
        font-size: 46px;
        line-height: 66px;
        color: $theme-color-1;
    }

    .card {
        transition: box-shadow $animation-time-long;
        cursor: pointer;
    }

    @include respond-below(xl) {
        .card-text {
            height: 48px;
            line-height: 18px;
        }

        .lead {
            font-size: 1.6rem;
        }

        i {
            font-size: 32px;
            line-height: 47px;
        }
    }
}

.log-indicator {
    width: 13px;
    height: 13px;
    border: 2px solid $theme-color-1;
    border-radius: 14px;
    display: inline-block;
}

/* 18.Calendar */
/*
.big-calendar-header {
  margin-bottom: 1em;
}

.rbc-month-header {
  min-height: 50px;
}

.rbc-today {
  background: initial;
}

.calendar-prev-btn,
.calendar-next-btn {
  outline: initial !important;
  box-shadow: initial !important;
  border-radius: 40px !important;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 0.55rem 0;
  background: $theme-color-1;
  color: $foreground-color;
  border: 1px solid $theme-color-1;
  line-height: 0.9 !important;
  font-size: 0.76rem;
  font-weight: normal !important;

  span {
    line-height: 1 !important;
    font-size: 0.76rem;
    font-weight: normal !important;
  }

  &:hover {
    background-color: transparent;
    border-color: lighten($theme-color-1, 10%);
    color: $theme-color-1;
  }
}

.calendar-prev-btn {
  margin-right: 5px;
}

.calendar-today-btn {
  padding: 0.4em 1.3em !important;
  height: unset !important;
}

.rbc-month-row {
  min-height: 5em;
  z-index: 1;

  @include respond-below(xs) {
    min-height: 3em;
  }
}

.rbc-month-view {
  border: initial;
}

.rbc-off-range-bg {
  background: initial;
}

.rbc-off-range {
  color: $primary-color;
  opacity: 0.3;
}

.rbc-day-bg+.rbc-day-bg,
.rbc-month-row+.rbc-month-row,
.rbc-header+.rbc-header,
.rbc-header {
  border-color: $separator-color-light !important;
}

.rbc-header {
  padding: 15px 5px;
  color: $theme-color-1;
}

.rbc-date-cell {
  padding: 10px;

  a {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    font-weight: initial;
    display: inline-block;
  }
}

.rbc-date-cell.rbc-now a {
  background: $theme-color-1;
  color: $foreground-color;
}

.rbc-event {
  font-size: 0.85em;
  border-radius: 25px;
  text-align: center;
  padding: 0px 5px;
  background: $theme-color-2;
}*/
.rbc-month-row {
    z-index: 1;
}

.fc-basic-view .fc-body .fc-row {
    min-height: 6em;

    @include respond-below(xs) {
        min-height: 3em;
    }
}

.fc-bootstrap4 .fc-day-top .fc-day-number {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
}

.fc-bootstrap4 td.fc-today {
    background: initial;

    .fc-day-number {
        background: $theme-color-1;
        color: $button-text-color;
    }
}

.fc-day-grid-container {
    height: 100%;
    overflow: visible;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
    padding: 5px;
}

.fc-view,
.fc-view > table {
    border-top: initial;
}

.fc table {
    border: initial;
}

.fc .table-bordered thead th,
.fc .table-bordered thead td {
    border-top: initial;
    border-bottom: initial;
    border-left: initial;
}

.fc td:first-of-type,
.fc th:first-of-type {
    border-left: initial;
    border-bottom: initial;
}

.fc td:last-of-type,
.fc th:last-of-type {
    border-right: initial;
    border-bottom: initial;
}

.fc-body .fc-row:last-of-type td {
    border-bottom: initial;
}

.fc tbody > tr > td.fc-widget-content:first-of-type {
    border-left: initial;
    border-right: initial;
    border-bottom: initial;
}

.fc table,
.fc .table-bordered th,
.fc .table-bordered td {
    border-color: $separator-color-light !important;
}

.fc-day-header {
    color: $theme-color-1;

    span {
        padding: 10px;
        display: inline-block;
    }
}

.fc-event {
    border: initial;
}

.fc-event,
.fc-event-dot {
    background-color: $theme-color-2;
    color: $button-text-color !important;
    padding: 1px 6px;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
}

/* 19.Datatable */
.ReactTable {
    border: initial;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
    padding-top: 20px;
    padding-bottom: 10px;
}

.ReactTable .rt-thead.-header {
    box-shadow: initial;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: initial;
    border: initial;
    text-align: left;
    font-weight: 700;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: 1px solid $separator-color-light;
}

.ReactTable .list-item-heading {
    margin-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
    border-right: initial;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 $theme-color-1;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 $theme-color-1;
}

/* 20.Alerts */
.alert {
    border-radius: 0;
}

.alert-primary {
    color: $theme-color-1;
    background-color: rgba($theme-color-1, 0.2);
    border-color: rgba($theme-color-1, 0.1);
}

.alert-secondary {
    color: $theme-color-2;
    background-color: rgba($theme-color-2, 0.2);
    border-color: rgba($theme-color-2, 0.1);
}

.alert-success {
    color: $success-color;
    background-color: rgba($success-color, 0.2);
    border-color: rgba($success-color, 0.1);
}

.alert-info {
    color: $info-color;
    background-color: rgba($info-color, 0.2);
    border-color: rgba($info-color, 0.1);
}

.alert-warning {
    color: $warning-color;
    background-color: rgba($warning-color, 0.2);
    border-color: rgba($warning-color, 0.1);
}

.alert-danger {
    color: $error-color;
    background-color: rgba($error-color, 0.2);
    border-color: rgba($error-color, 0.1);
}

.alert-light {
    color: $light-btn-background;
    background-color: rgba($light-btn-background, 0.2);
    border-color: rgba($light-btn-background, 0.1);
}

.alert-dark {
    color: $dark-btn-background;
    background-color: rgba($dark-btn-background, 0.2);
    border-color: rgba($dark-btn-background, 0.1);
}

.alert-dismissible .close {
    text-shadow: initial;
}

.alert *[data-notify='title'] {
    display: block;
    font-size: 0.9rem;
}

div[data-notify='container'] {
    padding: 18px;
}

.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    padding: 0px 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
}

.notification {
    box-sizing: border-box;
    padding: 15px 15px 15px 58px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: 0.9;
    margin-top: 15px;
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
    opacity: 1;
}

.notification-enter {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
}

.notification-leave {
    visibility: visible;
    transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
}

/* 21.Forms */
.av-invalid .av-label {
    color: $primary-color;
}

.av-tooltip .invalid-feedback {
    border-radius: 15px;
    padding: 0.5rem 1rem;
    font-size: 0.76rem;
    color: $foreground-color;
    background: $error-color;
    text-align: center;
    width: unset !important;
    position: absolute;
    z-index: 1;
    margin-top: -0.2rem;
    left: 50%;
    transform: translateX(-50%);

    &:after {
        content: '';
        position: absolute;
        top: -5px;
        left: -2.5px;
        margin-left: 50%;
        width: 10px;
        height: 5px;
        border-bottom: solid 5px $error-color;
        border-left: solid 5px transparent;
        border-right: solid 5px transparent;
    }
}

.form-inline {
    .form-group {
        @include respond-below(xs) {
            width: 100%;
        }
    }
}

.form-check-label,
.custom-control-label {
    line-height: 24px;
}

.react-tagsinput {
    background-color: $input-background;
    border: 1px solid $separator-color;
    outline: initial;
    box-shadow: initial;
}

.react-tagsinput--focused {
    border: 1px solid rgba($theme-color-1, 0.6);
}

.react-tagsinput-tag {
    background: $theme-color-1;
    border-radius: 15px;
    padding: 0px 10px;
    margin-bottom: 0px;
    display: inline-block;
    font-size: 12px;
    color: $foreground-color;
    border: initial;
}

.form-control:focus {
    background: $input-background;
    color: $primary-color;
}

.bootstrap-tagsinput {
    width: 100%;
}

.bootstrap-tagsinput input {
    padding: 0;
}

.form-control:focus {
    border-color: rgba($theme-color-1, 0.6);
}

select.form-control:not([size]):not([multiple]) {
    height: calc(2.4rem + 3px);
}

.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: rgba($primary-color, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
    background-color: transparent;
}

.custom-checkbox .custom-control-label.indeterminate::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='" + $button-text-color + "' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='" + $button-text-color + "' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='" + $button-text-color + "'/%3E%3C/svg%3E");
}

.custom-control-label,
.custom-control-input {
    outline: initial !important;
    box-shadow: initial !important;
}

.custom-control-input {
    left: 1px;
    top: 3px;
    opacity: 0;
    z-index: 1;
}

.custom-control-label::before {
    border: 1px solid $muted-color;
    background: initial;
}

.custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::before {
    background-color: $theme-color-1;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: $theme-color-1;
    box-shadow: initial !important;
    border: 1px solid $theme-color-1;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $theme-color-1;
    box-shadow: initial !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $theme-color-1;
    box-shadow: initial !important;
}

.custom-control-label::after,
.custom-control-label::before {
    box-shadow: initial !important;
}

.custom-control-label::before {
    top: 0.25rem;
}

.custom-control-label::after {
    top: 0.25rem;
}

.btn.rotate-icon-click i {
    transition: transform 0.5s;
}

.btn.rotate i {
    transform: rotate(180deg);
}

.btn .custom-control-label::before {
    border: 1px solid $button-text-color;
}

.btn .custom-control-input:checked ~ .custom-control-label::before {
    border: 1px solid $button-text-color;
}

.btn-group-icon {
    line-height: 22px;
}

.valid-tooltip,
.invalid-tooltip {
    border-radius: 15px;
    padding: 0.5rem 1rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.76rem;
}

.valid-tooltip {
    background-color: rgba(40, 167, 69, 1);
    margin-top: -0.2rem;
}

.invalid-tooltip {
    background-color: rgba(220, 53, 69, 1);
    margin-top: -0.2rem;
}

.valid-tooltip:after {
    content: '';
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px rgba(40, 167, 69, 1);
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
}

.invalid-tooltip:after {
    content: '';
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px rgba(220, 53, 69, 1);
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
}

.valid-icon {
    position: absolute;
    bottom: 8px;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0;
    font-size: 0.76rem;
    line-height: 1;
    border-radius: 0.2rem;
    right: 4px;
    color: $success-color;
}

.invalid-icon {
    position: absolute;
    bottom: 2px;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0;
    font-size: 0.875rem;
    line-height: 1;
    border-radius: 0.2rem;
    right: 4px;
    color: $error-color;
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-icon,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-icon,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-icon,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-icon {
    display: block;
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-icon,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-icon,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-icon,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-icon {
    display: block;
}

.autosuggest {
    position: relative;
}

.react-autosuggest__suggestions-container {
    border-radius: $border-radius;
    z-index: 20;
    box-shadow: initial;
    margin-top: -1px;
    background: $foreground-color;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    top: 100%;
}

.react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.react-autosuggest__suggestion {
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    background: $foreground-color !important;
    color: $primary-color !important;

    &:hover,
    &:active {
        background: lighten($theme-color-1, 10%) !important;
        color: $foreground-color !important;
    }
}

.react-autosuggest__input--open {
    border: 1px solid rgba($theme-color-1, 0.6) !important;
}

.react-autosuggest__suggestions-container--open {
    border: 1px solid rgba($theme-color-1, 0.6);
    border-top: initial;
    border-width: 1px !important;
}

.react-select {
    outline: initial !important;
    box-shadow: none !important;
}

.react-select__value-container {
    outline: initial !important;
    box-shadow: none !important;
    padding: 0.45rem 0.75rem 0.4rem 0.75rem !important;
    // height: calc(2.4rem + 3px) !important;
    background: $foreground-color !important;
}

.react-select .react-select__dropdown-indicator {
    color: $secondary-color;
}

.react-select .react-select__menu-list {
    padding-bottom: 0;
    padding-top: 0;
}

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
    color: $primary-color;
}

.react-select .react-select__dropdown-indicator,
.react-select
    .react-select__control--is-focused
    .react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select
    .react-select__control--is-focused
    .react-select__clear-indicator {
    outline: initial;
    box-shadow: initial;

    &:active,
    &:focus,
    &:hover {
        color: $theme-color-1 !important;
    }
}

.react-select__control {
    border-radius: $border-radius !important;
    border: 1px solid $separator-color !important;
    background: $input-background !important;
    outline: initial !important;
    box-shadow: none !important;
}

.react-select__indicator-separator {
    display: none;
}

.react-select__dropdown-indicator svg {
    width: 15px;
    height: 15px;
}

.react-select__option {
    background: $foreground-color !important;
    color: $primary-color !important;

    &:hover,
    &:active {
        background: $theme-color-1 !important;
        color: $button-text-color !important;
    }
}

.react-select__option--is-selected {
    background: $theme-color-1 !important;
    color: $button-text-color !important;
}

.react-select__control--is-focused {
    border-color: rgba($theme-color-1, 0.6) !important;
}

.react-select__multi-value {
    background: transparent !important;
    border: 1px solid $separator-color;
}

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
    background: transparent !important;
    color: $theme-color-1 !important;
}

.react-select .react-select__menu {
    border-radius: $border-radius !important;
    z-index: 20 !important;
    box-shadow: initial !important;
    border: 1px solid rgba($theme-color-1, 0.6) !important;
    border-top: initial !important;
    margin-top: -1px !important;
    background-color: $foreground-color;
    border-width: 1px !important;
}

.react-select__single-value {
    bottom: 0;
    top: 50%;
    padding-top: 2px;
}

.react-datepicker {
    background-color: $input-background;
    border: $separator-color;
}

.react-datepicker__input-container input {
    background-color: $input-background;
    border: $separator-color;
}

.react-datepicker__input-container input:focus {
    border-color: rgba($theme-color-1, 0.6) !important;
}

.react-datepicker-popper {
    z-index: 20;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__input-container input {
    font-size: 0.8rem;
    padding: 0.65rem 0.75rem 0.5rem 0.75rem !important;
    height: calc(2.4rem + 3px) !important;
    border: 1px solid $separator-color;
    border-radius: $border-radius;
    width: 100%;
    outline: initial;
}

.react-datepicker {
    border: 1px solid $separator-color;
    border-radius: $border-radius;
    font-family: 'Nunito', sans-serif;
}

.react-datepicker__header {
    background-color: $input-background;
    border-bottom: initial;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 35px;
    height: 35px;
    line-height: 2.3rem;
    border-radius: 0 !important;
    margin: 0;
    outline: initial !important;
}

.react-datepicker__day:hover {
    background: $separator-color;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
    background: $theme-color-1;
}

.react-datepicker__day--today {
    font-weight: 400;
    color: $button-text-color !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: $foreground-color;
}

.react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: $separator-color;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
    color: $primary-color;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: $primary-color;
}

.react-datepicker__input-container input {
    color: $primary-color;
}

.react-datepicker__time-container {
    border-left: 1px solid $separator-color;
    width: 71px;
}

.react-datepicker__time-container .react-datepicker__time {
    background-color: $input-background;
    color: $primary-color;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    color: $button-text-color;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-top-color: $foreground-color;
}

.react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color: $separator-color;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background: $background-color;
}

.react-datepicker__triangle {
    left: 30px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    background: $theme-color-1;
}

.react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range
    ) {
    background: $separator-color;
}

.react-datepicker.embedded {
    border: initial;
    width: 100%;

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 14.28%;
    }

    .react-datepicker__month-container {
        width: 100%;
    }
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 87px;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    padding-left: 0;
    padding-right: 30px;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background: $theme-color-1;
}

.react-fine-uploader-gallery-dropzone,
.react-fine-uploader-gallery-nodrop-container {
    min-height: 118px;
    border: 1px solid $separator-color;
    background: $input-background;
    padding: 10px 10px;
    border-radius: $border-radius;
    color: $primary-color;
    padding: 0 !important;
}

.react-fine-uploader-gallery-dropzone-active {
    border: 1px solid rgba($theme-color-1, 0.6) !important;
}

.react-fine-uploader-gallery-dropzone {
    button svg {
        fill: $theme-color-1;
    }
}

.react-fine-uploader-gallery-file-input-container {
    display: inline-block;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute !important;
    margin: 0;
    padding: 0;
}

.react-fine-uploader-gallery-dropzone-content,
.react-fine-uploader-gallery-nodrop-content {
    font-size: 0.8rem;
    color: $primary-color;
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
}

.react-fine-uploader-gallery-files {
    padding: 10px;
    margin-bottom: 0;
}

.react-fine-uploader-gallery-file {
    display: flex;
    flex-direction: row;
    width: 260px;
    height: unset;
    box-shadow: initial !important;
    border-radius: $border-radius;
    font-size: 0.8rem;
    border: 1px solid $separator-color;
    float: left;
    margin: 16px;
}

.react-fine-uploader-gallery-thumbnail-container {
    display: block;
    height: unset !important;
    text-align: center;
    width: 30% !important;
    height: 62px !important;
    overflow: hidden;
}

.react-fine-uploader-gallery-progress-bar-container {
    width: 100%;
    height: 3px;
    background: $separator-color;
}

.react-fine-uploader-gallery-progress-bar,
.react-fine-uploader-gallery-total-progress-bar {
    background: $theme-color-1;
    box-shadow: initial;
}

.react-fine-uploader-gallery-status,
.react-fine-uploader-filesize-value,
.react-fine-uploader-filesize-unit {
    font-style: initial;
    color: $theme-color-1;
    font-size: 0.76rem;
}

.react-fine-uploader-filename.react-fine-uploader-gallery-filename {
    font-weight: normal;
}

.react-fine-uploader-gallery-total-progress-bar-container {
    display: none;
    background: $separator-color;
}

.react-fine-uploader-gallery-thumbnail {
    height: 100%;
}

.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
    height: 36px;
    width: 36px;
}

.react-fine-uploader-gallery-upload-failed-icon {
    fill: $button-text-color;
    opacity: 1;
}

.react-fine-uploader-gallery-file-footer {
    width: 70%;
    padding: 15px;
    background: $foreground-color;
}

.react-fine-uploader-gallery-thumbnail-icon-backdrop {
    opacity: 0;
}

.react-fine-uploader-gallery-thumbnail-icon-backdrop,
.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
    left: 38px;
    position: absolute;
    top: 50%;
}

.dropzone .dz-message {
    text-align: center;
}

.dropzone.dz-clickable {
    cursor: pointer;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    margin: 0;
}

.dropzone .dz-preview.dz-image-preview {
    width: 260px;
    height: unset;
    min-height: unset;
    border: 1px solid $separator-color;
    border-radius: $border-radius;
    background: $foreground-color;
    color: $primary-color;

    strong {
        font-weight: normal;
    }

    .remove {
        position: absolute;
        right: 5px;
        top: 5px;
        color: $theme-color-1;
    }

    .dz-details {
        position: static;
        display: block;
        opacity: 1;
        text-align: left;
        min-width: unset;
        z-index: initial;
        color: $primary-color;
    }

    .dz-error-mark {
        color: $button-text-color;
        transform: translateX(-50%) translateY(-50%) !important;
        transition: initial !important;
        animation: initial !important;
        margin-left: 0;
        margin-top: 0;

        i {
            font-size: 26px;
        }
    }

    .dz-progress {
        width: 90%;
        margin-left: 0;
        margin-top: 0;
        left: 50%;
        right: 0;
        transform: translateX(-50%);
        height: 5px;

        .dz-upload {
            width: 100%;
            background: $theme-color-1;
        }
    }

    .dz-error-message {
        border-radius: 15px;
        background: $error-color;
        top: 60px;

        &:after {
            border-bottom: 6px solid $error-color;
        }
    }
}

.custom-switch {
    display: block;
    background: $separator-color;
    width: 58px;
    height: 27px;
    border: 1px solid $separator-color;

    &:after {
        width: 18px;
        height: 18px;
        top: 3px;
        margin-left: 3px;
        box-shadow: initial;
        transform: initial !important;
        animation-name: unset !important;
        background: $foreground-color;
    }

    &.rc-switch-checked:after {
        left: 30px;
    }

    &.rc-switch-checked.custom-switch-primary {
        background: $theme-color-1;
        border: 1px solid $theme-color-1;
    }

    &.rc-switch-checked.custom-switch-secondary {
        background: $theme-color-2;
        border: 1px solid $theme-color-2;
    }

    &.custom-switch-primary-inverse {
        border: 1px solid $separator-color;
    }

    &.rc-switch-checked.custom-switch-primary-inverse {
        background: $input-background;
        border: 1px solid $theme-color-1;

        &:after {
            background: $theme-color-1;
        }
    }

    &.custom-switch-secondary-inverse {
        border: 1px solid $separator-color;
    }

    &.rc-switch-checked.custom-switch-secondary-inverse {
        background: $input-background;
        border: 1px solid $theme-color-2;

        &:after {
            background: $theme-color-2;
        }
    }
}

.custom-switch .custom-switch-input + .custom-switch-btn {
    background: $separator-color;
}

.custom-switch.custom-switch-primary
    .custom-switch-input:checked
    + .custom-switch-btn {
    background: $theme-color-1;
    border: 1px solid $theme-color-1;
}

.custom-switch.custom-switch-secondary
    .custom-switch-input:checked
    + .custom-switch-btn {
    background: $theme-color-2;
    border: 1px solid $theme-color-2;
}

.custom-switch.custom-switch-primary-inverse
    .custom-switch-input
    + .custom-switch-btn {
    border: 1px solid $separator-color;
}

.custom-switch.custom-switch-primary-inverse
    .custom-switch-input:checked
    + .custom-switch-btn {
    background: $input-background;
    border: 1px solid $theme-color-1;
}

.custom-switch.custom-switch-primary-inverse
    .custom-switch-input:checked
    + .custom-switch-btn:after {
    background: $theme-color-1;
}

.custom-switch.custom-switch-secondary-inverse
    .custom-switch-input
    + .custom-switch-btn {
    border: 1px solid $separator-color;
}

.custom-switch.custom-switch-secondary-inverse
    .custom-switch-input:checked
    + .custom-switch-btn {
    background: $input-background;
    border: 1px solid $theme-color-2;
}

.custom-switch.custom-switch-secondary-inverse
    .custom-switch-input:checked
    + .custom-switch-btn:after {
    background: $theme-color-2;
}

.custom-switch .custom-switch-input + .custom-switch-btn:after {
    background: $input-background;
}

.custom-switch .custom-switch-input + .custom-switch-btn {
    border-color: $separator-color;
}

.input-group-text {
    border-radius: $border-radius;
    background-color: lighten($input-background, 5%);
    border-color: $separator-color;
    color: $primary-color;
    font-size: 0.8rem;
    padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.form-control {
    border-radius: $border-radius;
    outline: initial !important;
    box-shadow: initial !important;
    font-size: 0.8rem;
    // padding: 0.75rem 0.75rem 0.5rem 0.75rem;
    padding: 0.7rem 0.75rem 0.65rem 0.75rem;
    line-height: 1.5;
    border: 1px solid $separator-color;
    background: $input-background;
    color: $primary-color;
}

@keyframes autofill {
    to {
        color: $primary-color;
        background: transparent;
    }
}

input:-webkit-autofill {
    animation-name: autofill;
    animation-fill-mode: both;
}

input:-webkit-autofill {
    -webkit-text-fill-color: $primary-color !important;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
    border-radius: $border-radius;
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
    border-radius: $border-radius;
}

.custom-select {
    border-radius: $border-radius;
    padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
    outline: initial !important;
    box-shadow: initial !important;
}

.custom-select {
    height: calc(2.5rem + 2px);
}

.custom-select:focus {
    border-color: $theme-color-1;
}

.custom-file-input:focus ~ .custom-file-label {
    border-color: rgba($theme-color-1, 0.6);
}

.custom-file-label::after {
    background: $foreground-color;
    color: $primary-color;
    border-color: $separator-color;
}

.custom-file-input {
    box-shadow: initial !important;
}

.custom-file-label {
    background: $foreground-color;
    border-color: $separator-color;
}

.custom-file-label {
    box-shadow: initial !important;
    border-radius: $border-radius;
    height: calc(2.5rem + 2px);
    padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.custom-file {
    height: calc(2.5rem + 2px);
}

.custom-file-label:focus,
.custom-file-input:focus {
    border-color: $theme-color-1;
}

.custom-file-label::after {
    height: calc(calc(2.5rem + 2px) - 1px * 2);
    padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.jumbotron {
    background: initial;
}

/* 22.Slider(Range) */
.rc-slider-tooltip {
    background: $input-background;
    color: $primary-color;
    border: 1px solid $separator-color;
    border-radius: $border-radius;
    text-align: center;
    top: 150%;
    bottom: unset;
    padding: 5px;
    transform: translateX(-50%);
}

.rc-slider-handle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: $foreground-color;
    cursor: default;
    border: 1px solid $separator-color !important;
    box-shadow: initial !important;
}

.rc-slider-track,
.rc-slider-rail,
.rc-slider-step {
    height: 7px;
}

.rc-slider-rail {
    border: 1px solid $separator-color;
    background: $foreground-color;
}

.rc-slider-handle {
    margin-top: -7px;
}

.rc-slider-track {
    background: $theme-color-1;
}

/* 23.Navs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: $foreground-color;
    background-color: $theme-color-1;
}

.nav-pills .nav-link {
    border-radius: 50px;
}

.nav-link {
    padding: 0.5rem 1rem;

    @include respond-below(sm) {
        padding: 0.5rem 0.5rem;
    }

    &.active {
        color: $theme-color-1;
    }
}

.page-item {
    padding: 10px;

    @include respond-below(sm) {
        padding: 3px;
    }

    .page-link {
        outline: initial !important;
        box-shadow: initial !important;
        line-height: 1.7;
        border-radius: 40px !important;
        min-width: 38px;
        text-align: center;
        height: 38px;
        padding: 0.55rem 0;

        @include respond-below(xs) {
            min-width: 30px;
            height: 30px;
            line-height: 0.9;
            font-size: 0.76rem;
        }

        &.next {
            background: $theme-color-1;
            color: $foreground-color;
            border: 1px solid $theme-color-1;
        }

        &.prev,
        &.prev {
            background: $theme-color-1;
            border: 1px solid $theme-color-1;
            color: $foreground-color;
        }

        &.first,
        &.last {
            background: transparent;
            color: $theme-color-1;
            border: 1px solid $theme-color-1;
            border-radius: 30px;

            &:hover {
                background: $theme-color-1;
                color: $foreground-color;
                border: 1px solid $theme-color-1;
            }
        }

        &:hover {
            background-color: transparent;
            border-color: lighten($theme-color-1, 10%);
            color: $theme-color-1;
        }
    }

    &.active .page-link {
        background: transparent;
        border: 1px solid $theme-color-1;
        color: $theme-color-1;
    }

    &.disabled .page-link {
        border-color: $separator-color;
        color: $separator-color;
        background: transparent;
    }
}

.page-link {
    background-color: transparent;
    border-color: transparent;
    color: $primary-color;
}

.btn-sm.page-link {
    padding: 0.5rem 0.5rem;
}

.pagination-lg {
    .page-item {
        padding: 15px;

        @include respond-below(sm) {
            padding: 3px;
        }

        .page-link {
            min-width: 50px;
            height: 50px;

            @include respond-below(sm) {
                min-width: 38px;
                height: 38px;
                padding: 0.55rem 0;
                line-height: 1.5;
                font-size: 0.8rem;
            }

            @include respond-below(xs) {
                min-width: 30px;
                height: 30px;
                line-height: 0.9;
                font-size: 0.76rem;
            }
        }
    }
}

.pagination-sm {
    .page-item {
        padding: 6px;

        @include respond-below(sm) {
            padding: 3px;
        }

        .page-link {
            min-width: 30px;
            height: 30px;
            line-height: 0.9;
            font-size: 0.76rem;
        }
    }
}

/* 24.Tooltip and popover */
.popover {
    border-radius: $border-radius;
    background-color: $foreground-color;
    border-color: $separator-color;

    .popover-body {
        color: $primary-color;
    }
}

.popover-header {
    background-color: transparent;
    border-bottom: initial;
}

.tooltip-inner {
    padding: 0.5rem 0.75rem;
    color: $primary-color;
    background-color: $foreground-color;
    border-radius: 0.1rem;
    border: 1px solid $separator-color;
}

.tooltip.show {
    opacity: 1;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^='right'] .arrow::before {
    border-right-color: $separator-color;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after {
    border-right-color: $foreground-color;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^='left'] .arrow::before {
    border-left-color: $separator-color;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::after {
    border-left-color: $foreground-color;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^='bottom'] .arrow::before {
    border-bottom-color: $separator-color;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::after {
    border-bottom-color: $foreground-color;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^='top'] .arrow::before {
    border-top-color: $separator-color;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after {
    border-top-color: $foreground-color;
}

.tooltip .arrow::before,
.tooltip .arrow::after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^='right'] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
    left: 0;
    border-right-color: $separator-color;
}

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^='right'] .arrow::after {
    left: 1px;
    border-right-color: $foreground-color;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^='right'] .arrow::after {
    border-width: 0.4rem 0.4rem 0.4rem 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
    bottom: 0;
    border-top-color: $separator-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^='top'] .arrow::after {
    border-width: 0.5rem 0.5rem 0;
}

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^='top'] .arrow::after {
    bottom: 1px;
    border-top-color: $foreground-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^='top'] .arrow::after {
    border-width: 0.4rem 0.4rem 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    top: 0;
    border-bottom-color: $separator-color;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::after {
    top: 1px;
    border-bottom-color: $foreground-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^='left'] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
    right: 0;
    border-left-color: $separator-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^='left'] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^='left'] .arrow::after {
    right: 0;
    border-left-color: $foreground-color;
}

.search-sm {
    position: relative;

    input {
        background: none;
        outline: initial !important;
        border: 1px solid $secondary-color;
        border-radius: 15px;
        padding: 0.25rem 0.75rem 0.25rem 0.75rem;
        font-size: 0.76rem;
        line-height: 1.3;
        color: $primary-color;
    }

    &:after {
        font-family: 'simple-line-icons';
        content: '\e090';
        font-size: 14px;
        border-radius: 10px;
        color: $secondary-color;
        position: absolute;
        width: 25px;
        right: 2px;
        text-align: center;
        cursor: pointer;
        top: 2px;
    }
}

.notification {
    border-radius: $border-radius;
    @include depth(1);
    padding: 25px 25px 25px 25px;
}

.notification:before {
    content: '';
    display: none;
}

.notification-primary {
    color: $theme-color-1;
    background-color: $foreground-color;
    border: 1px solid $theme-color-1;
    opacity: 1;

    .notification-message {
        .title {
            color: $theme-color-1;
        }

        .message {
            color: $primary-color;
        }
    }

    &.filled {
        color: $foreground-color;
        background-color: $theme-color-1;

        .notification-message {
            .title {
                color: $foreground-color;
            }

            .message {
                color: $foreground-color;
            }
        }
    }
}

.notification-secondary {
    color: $theme-color-2;
    background-color: $foreground-color;
    border: 1px solid $theme-color-2;
    opacity: 1;

    .notification-message {
        .title {
            color: $theme-color-2;
        }

        .message {
            color: $secondary-color;
        }
    }

    &.filled {
        color: $foreground-color;
        background-color: $theme-color-2;

        .notification-message {
            .title {
                color: $foreground-color;
            }

            .message {
                color: $foreground-color;
            }
        }
    }
}

.notification-info {
    color: $info-color;
    background-color: $foreground-color;
    border: 1px solid $info-color;
    opacity: 1;

    .notification-message {
        .title {
            color: $info-color;
        }

        .message {
            color: $secondary-color;
        }
    }

    &.filled {
        color: $foreground-color;
        background-color: $info-color;

        .notification-message {
            .title {
                color: $foreground-color;
            }

            .message {
                color: $foreground-color;
            }
        }
    }
}

.notification-success {
    color: $success-color;
    background-color: $foreground-color;
    border: 1px solid $success-color;
    opacity: 1;

    .notification-message {
        .title {
            color: $success-color;
        }

        .message {
            color: $secondary-color;
        }
    }

    &.filled {
        color: $foreground-color;
        background-color: $success-color;

        .notification-message {
            .title {
                color: $foreground-color;
            }

            .message {
                color: $foreground-color;
            }
        }
    }
}

.notification-warning {
    color: $warning-color;
    background-color: $foreground-color;
    border: 1px solid $warning-color;
    opacity: 1;

    .notification-message {
        .title {
            color: $warning-color;
        }

        .message {
            color: $secondary-color;
        }
    }

    &.filled {
        color: $foreground-color;
        background-color: $warning-color;

        .notification-message {
            .title {
                color: $foreground-color;
            }

            .message {
                color: $foreground-color;
            }
        }
    }
}

.notification-error {
    color: $error-color;
    background-color: $foreground-color;
    border: 1px solid $error-color;
    opacity: 1;

    .notification-message {
        .title {
            color: $error-color;
        }

        .message {
            color: $secondary-color;
        }
    }

    &.filled {
        color: $foreground-color;
        background-color: $error-color;

        .notification-message {
            .title {
                color: $foreground-color;
            }

            .message {
                color: $foreground-color;
            }
        }
    }
}

/* 25.Progress */
.progress {
    background-color: $separator-color-light;
    height: 3px;
}

.progress-bar {
    background-color: $theme-color-1;
}

.CircularProgressbar-text {
    fill: $primary-color !important;
}

.progress-bar-circle {
    width: 54px;
    height: 54px;

    svg path:first-of-type {
        stroke: $separator-color;
    }

    svg path:last-of-type {
        stroke: $theme-color-1;
    }

    &.progress-bar-banner {
        svg path:first-of-type {
            stroke: darken($theme-color-1, 15%);
        }

        svg path:last-of-type {
            stroke: $button-text-color;
        }
    }
}

.progress-banner {
    height: 200px;
    background-image: linear-gradient(
        to right top,
        $gradient-color-2,
        $gradient-color-3,
        $gradient-color-1
    );
    transition: 0.5s;
    background-size: 200% auto;
    cursor: pointer;

    .CircularProgressbar .CircularProgressbar-text {
        fill: $button-text-color !important;
    }

    .lead {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;

        @include respond-below(lg) {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }
    }

    i {
        font-size: 2.7rem;
        margin-bottom: 1rem;

        @include respond-below(lg) {
            font-size: 2rem;
            margin-bottom: 0.2rem;
        }
    }

    .progress-bar-circle.progress-bar-banner {
        width: 120px;
        height: 120px;

        svg path:last-of-type {
            stroke: darken($button-text-color, 10%);
        }

        @include respond-below(lg) {
            width: 80px;
            height: 80px;
        }
    }

    .progress-bar-banner {
        .progressbar-text {
            color: $primary-color !important;
            font-size: 1.7rem;
            width: 110px;
            font-weight: 300;

            @include respond-below(lg) {
                font-size: 1.2rem;
                margin-bottom: 0.2rem;
            }
        }
    }

    &:hover {
        background-position: right top;
    }
}

/* 26.Rating */
.br-theme-bootstrap-stars .br-widget a.br-active:after {
    color: $theme-color-1;
}

.br-theme-bootstrap-stars .br-widget a.br-selected:after {
    color: $theme-color-1;
}

.react-rater {
    display: block !important;
    font-size: 16px;
    color: $separator-color;
}

.react-rater-star {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'simple-line-icons';
    color: $separator-color;
    margin-right: 3px;
    font-size: 18px;

    &.is-active,
    &.will-be-active {
        color: $theme-color-1;
    }

    &:after {
        content: '\e09b';
    }
}

/* 27.Sortable */
.sortable {
    cursor: default;
}

.sortable span {
    vertical-align: middle;
}

.sortable-ghost {
    opacity: 0.5;
}

/* 28.Spinner */
.spinner {
    width: 36px;
    text-align: center;
}

.spinner > span {
    width: 6px;
    height: 6px;
    background-color: $button-text-color;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
    animation: sk-bouncedelay 1.2s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* 29.Croppper */
.cropper-line {
    background-color: $theme-color-1;
}

.cropper-point {
    background-color: $theme-color-1;
}

.cropper-view-box {
    outline-color: $theme-color-1;
    outline: $theme-color-1;
}

.cropper-preview {
    overflow: hidden;
}

#cropperContainer {
    height: 300px;
    display: none;
}

/* 30.Modal */
.modal {
    .modal-header,
    .modal-body,
    .modal-footer {
        padding: 1.75rem;
    }

    .modal-header {
        border-bottom: 1px solid $separator-color;
    }

    .modal-footer {
        border-top: 1px solid $separator-color;
    }

    .close {
        color: $primary-color;
        text-shadow: initial;
    }
}

.modal-content {
    border: initial;
    border-radius: 0.1rem;
    background: $foreground-color;
}

.modal-dialog {
    @include depth(2);
}

.modal-right {
    padding-right: 0 !important;

    .modal-dialog {
        margin: 0 auto;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
        max-width: 380px;
    }

    .modal-content {
        min-height: 100%;
    }

    .modal-header {
        height: $navbar-height;

        @include respond-below(xl) {
            height: $navbar-height-lg;
        }

        @include respond-below(lg) {
            height: $navbar-height-md;
        }

        @include respond-below(sm) {
            height: $navbar-height-xs;
        }
    }

    .modal-footer {
        justify-content: center;
    }

    .modal.fade .modal-dialog {
        transform: translate(25%, 0) !important;
    }

    .modal.show .modal-dialog {
        transform: translate(0, 0) !important;
    }
}

/* 31.Authorization */
.auth-card {
    display: flex;
    flex-direction: row;
    @include depth(1);

    .image-side {
        width: 0;
        background: #419650;
        display: flex;
        flex-wrap: wrap;
        padding: 10% 0;

        .slogan-heading {
            text-align: center;
        }

        .text-line-1 {
            align-self: stretch;
        }

        .text-line-2 {
            align-self: center;
        }

        .company-logo {
            margin: 40% auto 0 auto;
            align-self: stretch;

            img {
                width: 300px;
            }
        }

        .h3 {
            line-height: 0.8rem;
        }
    }

    .form-side {
        width: 100%;
        padding: 10% 25%;
    }

    .login-btn {
        width: 60%;
        margin: 5% auto 5% auto;
    }

    @include respond-below(md) {
        //flex-direction: column;
        .image-side {
            width: 0;
        }

        .company-logo {
            visibility: hidden;
        }

        .form-side {
            width: 100%;
            padding: 60px;
        }
    }

    @include respond-below(sm) {
        p.h2 {
            font-size: 1.6rem;
        }
    }

    @include respond-below(xs) {
        flex-direction: column;

        .form-side {
            padding: 35px 30px;
        }

        .logo-single {
            margin-bottom: 20px;
        }

        p.h2 {
            font-size: 1.4rem;
        }
    }
}

/* 32.Html Editors */
.html-editor {
    height: 350px;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: $theme-color-1;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: $theme-color-1;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
    stroke: $theme-color-1;
}

.ql-toolbar.ql-snow {
    border-color: $separator-color;
}

.ql-container.ql-snow {
    border-color: $separator-color;
}

.html-editor-bubble {
    height: 200px;
}

.html-editor-bubble .ql-editor {
    border: 1px solid $separator-color;
}

.ql-tooltip {
    z-index: 4;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    height: 350px;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners {
    box-shadow: initial !important;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-focused {
    border-color: $separator-color;
}

.ck.ck-toolbar {
    background: none !important;
}

.RichEditor-root {
    background: $foreground-color;
    border: 1px solid $separator-color;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    padding: 15px;
}

.RichEditor-editor {
    border-top: 1px solid $separator-color;
    cursor: text;
    font-size: 14px;
    margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
    min-height: 200px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid $separator-color;
    color: $secondary-color;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: $secondary-color;
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
}

.RichEditor-controls {
    font-family: 'Nunito', sans-serif;
    font-size: 12px;
    margin-bottom: 5px;
    user-select: none;
}

.RichEditor-styleButton {
    color: $separator-color;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
    color: $primary-color;
    font-size: 0.76rem;
}

.RichEditor-activeButton {
    color: $primary-color;
}

.ql-editor {
    height: 200px;
}

.ql-container.ql-bubble {
    border: 1px solid $separator-color;
}

/* 33.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
    width: 14.28%;
    text-align: center;
    float: left;
    height: 100px;

    .glyph-icon,
    .fa {
        font-size: 32px;
    }

    .author-name {
        display: none;
    }

    .class-name {
        font-size: 0.76rem;
        color: $muted-color !important;
    }

    @include respond-below(lg) {
        width: 16.66%;
    }

    @include respond-below(md) {
        width: 20%;
    }

    @include respond-below(sm) {
        width: 25%;
    }

    @include respond-below(xs) {
        width: 50%;
    }
}

.chart-container {
    height: 300px;
}

.theme-colors {
    width: 280px;
    position: fixed;
    z-index: 1030;
    top: 50%;
    right: 0;
    background: $foreground-color;
    @include depth(1);
    transform: translate(280px, -50%);
    transition: transform 0.4s ease-out;
    padding-top: 10px;
    padding-bottom: 10px;

    .theme-button {
        position: absolute;
        left: -34px;
        background: $foreground-color;
        padding: 13px 7px 13px 7px;
        border-radius: 0.2rem;
        color: $primary-color;
        box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
        font-size: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: $theme-color-1;
    }

    .theme-color {
        width: 24px;
        height: 24px;
        display: inline-block;
        border-radius: 20px;
        transition: background 0.25s;

        &.active,
        &:hover {
            background: $foreground-color;
        }
    }

    .theme-color-purple {
        border: 3px solid $theme-color-purple;
        background: $theme-color-purple;
    }

    .theme-color-blue {
        border: 3px solid $theme-color-blue;
        background: $theme-color-blue;
    }

    .theme-color-orange {
        border: 3px solid $theme-color-orange;
        background: $theme-color-orange;
    }

    .theme-color-green {
        border: 3px solid $theme-color-green;
        background: $theme-color-green;
    }

    .theme-color-red {
        border: 3px solid $theme-color-red;
        background: $theme-color-red;
    }

    &.shown {
        transform: translate(0, -50%);
    }
}

/* 34.Floating Label */
.has-float-label {
    display: block;
    position: relative;
}

.has-float-label label::after,
.has-float-label > span::after {
    background: $foreground-color !important;
}

.has-top-label label,
.has-top-label > span,
.has-float-label label,
.has-float-label > span {
    color: rgba($primary-color, 0.7);
}

.has-float-label label,
.has-float-label > span:last-of-type {
    position: absolute;
    cursor: text;
    font-size: 90%;
    opacity: 1;
    top: -0.4em;
    left: 0.75rem;
    z-index: 3;
    line-height: 1;
    padding: 0 1px;
}

.has-float-label label::after,
.has-float-label > span::after {
    content: ' ';
    display: block;
    position: absolute;
    height: 5px;
    top: 3px;
    left: -0.2em;
    right: -0.2em;
    z-index: -1;
}

.has-float-label .form-control::-webkit-input-placeholder {
    opacity: 1;
}

.has-float-label .form-control::-moz-placeholder {
    opacity: 1;
}

.has-float-label .form-control:-ms-input-placeholder {
    opacity: 1;
}

.has-float-label .form-control::placeholder {
    opacity: 1;
}

.has-float-label
    .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
    opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
    opacity: 0;
}

.has-float-label
    .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
    opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
}

.input-group .has-float-label {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.input-group .has-float-label .form-control {
    width: 100%;
}

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
}

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
    padding: 1.7rem 0.75rem 0.5rem 0.75rem !important;
}

.has-top-label {
    display: block;
    position: relative;

    .react-tagsinput-input {
        padding: 0;
        margin: 0;
        font-size: 0.8rem;
        line-height: 1;
    }
}

.has-top-label .react-select__value-container .css-rsyb7x {
    margin: 0 !important;
    padding: 0 !important;
}

.has-top-label label,
.has-top-label > span:last-of-type {
    position: absolute;
    cursor: text;
    font-size: 76%;
    opacity: 1;
    top: 0.7rem;
    left: 0.75rem;
    z-index: 3;
    line-height: 1;
    padding: 0 1px;
    font-weight: 600;
}

.has-top-label label::after,
.has-top-label > span::after {
    content: ' ';
    display: block;
    position: absolute;
    height: 2px;
    top: 50%;
    left: -0.2em;
    right: -0.2em;
    z-index: -1;
}

.has-top-label .form-control::-webkit-input-placeholder {
    opacity: 1;
}

.has-top-label .form-control::-moz-placeholder {
    opacity: 1;
}

.has-top-label .form-control:-ms-input-placeholder {
    opacity: 1;
}

.has-top-label .form-control::placeholder {
    opacity: 1;
}

.has-top-label
    .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
    opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
    opacity: 0;
}

.has-top-label
    .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
    opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus) + * {
    font-size: 150%;
    opacity: 0.5;
    top: 0.3em;
}

.has-top-label .react-select__single-value {
    top: unset !important;
    bottom: -3px !important;
    margin-left: 0 !important;
}

.input-group .has-top-label {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.input-group .has-top-label .form-control {
    width: 100%;
}

.input-group .has-top-label:not(:last-child),
.input-group .has-top-label:not(:last-child) .form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
}

.input-group .has-top-label:not(:first-child),
.input-group .has-top-label:not(:first-child) .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
    min-height: calc(3.3rem + 2px) !important;
}

.form-group.has-top-label select.form-control:not([size]):not([multiple]) {
    height: calc(3.3rem + 2px);
    padding: 1.7rem 0.75rem 0.5rem 0.5rem;
}

/* 35.Loading */
.loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid rgba($theme-color-1, 0.2);
    border-radius: 50%;
    border-top-color: $theme-color-1;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    position: fixed;
    z-index: 10000000000000;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

/* 36.Context Menu */
.react-contextmenu {
    box-shadow: initial;
    border-radius: $border-radius;
    background: $input-background;
    border: 1px solid rgba($primary-color, 0.15);
    padding: 0.5rem 0;
    opacity: 0;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    padding: 0.5rem 1.5rem;
    background: $input-background;
    color: $primary-color;
    cursor: pointer;

    span {
        font-family: 'Nunito', sans-serif;
        font-size: 0.8rem;
        font-weight: 400;
        margin-left: 0.5rem;
        line-height: 15px;
    }

    &:hover {
        color: $primary-color;
        text-decoration: none;
        background-color: $background-color;
    }
}

.react-contextmenu-item:not(.react-contextmenu-item--disabled):hover {
    color: $primary-color;
    text-decoration: none;
    background-color: $background-color;
}

.card.react-contextmenu--visible,
.card.active {
    @include depth(2);
}

/* Videojs */
.video-js .vjs-big-play-button {
    background: $input-background;
    height: 1.2em;
    border-radius: 0.75em;
    line-height: initial;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.5em;
    width: 2.5em;
    border: 0.06666em solid $input-background;

    .vjs-icon-placeholder {
        color: $theme-color-1;
    }
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
    background-color: darken($input-background, 5%);
    border-color: darken($input-background, 5%);
}

.vjs-control {
    text-shadow: initial !important;
    outline: initial !important;
}

.video-js .vjs-control-bar {
    background: initial;
    margin: 1.75rem;
    width: calc(100% - 3.5rem);

    .vjs-control.vjs-button,
    .vjs-remaining-time,
    .vjs-volume-panel {
        margin-right: 0.5em;
        background: $input-background;
        color: $theme-color-1;
        border-radius: 15px;
        @include depth(2);
    }

    .vjs-progress-control.vjs-control {
        background: $input-background;
        border-radius: 15px;
        margin-right: 0.5em;
        @include depth(2);
    }

    .vjs-mute-control.vjs-control {
        box-shadow: initial;
    }

    .vjs-progress-holder {
        height: 3px;
        font-size: 1.6em !important;
    }

    .vjs-load-progress,
    .vjs-load-progress div {
        background-color: rgba($theme-color-1, 0.2);
    }

    .vjs-play-progress:before {
        font-size: 0.55em;
        top: -0.2em;
    }

    .vjs-progress-holder {
        margin: 0 17px;
    }

    .vjs-slider {
        text-shadow: initial !important;
        outline: initial !important;
        background-color: darken($separator-color-light, 10%);
    }

    .vjs-play-progress {
        background: $theme-color-1;
    }

    .vjs-play-progress:before {
        color: $theme-color-1;
    }

    .vjs-volume-horizontal {
        margin-left: -1.5em;
        width: 4em;
    }

    .vjs-volume-panel .vjs-volume-level {
        background: $theme-color-1;
    }
}

.video-js.audio {
    background: initial;

    .vjs-big-play-button {
        display: none;
    }

    .vjs-control-bar {
        display: flex;
    }

    .vjs-fullscreen-control {
        display: none;
    }

    .vjs-control-bar {
        margin-bottom: 0;
    }

    .vjs-control.vjs-button,
    .vjs-remaining-time,
    .vjs-volume-panel {
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
    }

    .vjs-progress-control.vjs-control {
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
    }

    .vjs-mute-control {
        box-shadow: initial !important;
    }

    .vjs-loading-spinner {
        display: none !important;
    }
}

/* 38.Landing Page */
.landing-page {
    font-size: 1rem;
    line-height: 1.6rem;
    color: $secondary-color;

    @include respond-below(sm) {
        font-size: 0.9rem;
        line-height: 1.4rem;
    }

    .mobile-menu {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        transform: translateX(300px);
        z-index: 25;
        width: 300px;
        background: $background-color;
        box-shadow: initial;
        transition: transform 0.5s, box-shadow 0.5s;
    }

    .logo-mobile {
        display: block;
        text-align: center;
        padding: 3rem 2rem;

        span {
            display: inline-block;
            width: 120px;
            height: 30px;
            background: url($lpLogoPathPinned);
            background-repeat: no-repeat;
        }
    }

    .main-container {
        overflow: hidden;
    }

    &.show-mobile-menu {
        .mobile-menu {
            transform: translateX(0);
            @include depth(2);
        }

        .main-container {
            .content-container,
            .landing-page-nav {
                transform: translateX(-300px);
            }
        }
    }

    .content-container {
        transition: transform 0.5s;
    }

    p {
        font-size: 1rem;
        line-height: 1.6rem;
        color: $secondary-color;

        @include respond-below(sm) {
            font-size: 0.9rem;
            line-height: 1.4rem;
        }
    }

    h1 {
        font-size: 3rem;
        color: $theme-color-1;
        margin-bottom: 2rem;

        @include respond-below(sm) {
            font-size: 2rem;
        }
    }

    h2 {
        font-size: 1.8rem;
        color: $theme-color-1;
        margin-bottom: 2rem;

        @include respond-below(md) {
            font-size: 1.6rem;
        }

        @include respond-below(sm) {
            font-size: 1.2rem;
        }
    }

    h3 {
        font-size: 1.4rem;
        line-height: 1.4;
        color: $theme-color-1;

        @include respond-below(sm) {
            font-size: 1.1rem;
        }
    }

    .semi-rounded {
        border-radius: 1.2rem !important;
    }

    .dropdown-toggle::after {
        opacity: 0.7;
    }

    .btn-outline-semi-light {
        border: 1px solid rgba($button-text-color, 0.3);
        color: $button-text-color;
        background: transparent;

        &:hover,
        &:active {
            border: 1px solid darken($theme-color-1, 10%);
            background: darken($theme-color-1, 10%);
        }
    }

    .landing-page-nav {
        z-index: 5 !important;
        top: 0;
        position: fixed;
        width: 100%;
        background: transparent;
        box-shadow: initial;
        transition: transform 0.5s;

        .headroom {
            transition: top 0.5s, background 0.5s, box-shadow 0.2s, opacity 0.2s,
                transform 0.5s !important;
        }

        .container {
            height: $main-menu-width;

            @include respond-below(xl) {
                height: $main-menu-width-lg;
            }

            @include respond-below(lg) {
                height: $main-menu-width-md;
            }

            @include respond-below(sm) {
                height: $main-menu-width-xs;
            }
        }

        .btn {
            padding-right: 1.75rem !important;
            padding-left: 1.75rem !important;
        }

        .navbar-logo {
            width: 120px;
            height: 40px;

            .white {
                display: inline-block;
                width: 120px;
                height: 40px;
                background: url($lpLogoPath);
                background-repeat: no-repeat;
            }

            .dark {
                width: 120px;
                height: 40px;
                background: url($lpLogoPathPinned);
                background-repeat: no-repeat;
                display: none;
            }
        }

        .mobile-menu-button {
            font-size: 20px;
            display: none;

            @include respond-below(md) {
                display: block;
            }
        }

        .navbar-nav {
            height: 100%;
            position: relative;

            li {
                height: 100%;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                position: relative;

                &:last-of-type {
                    padding-right: 0;
                }

                &.active::before {
                    content: ' ';
                    background: $button-text-color;
                    border-radius: 10px;
                    position: absolute;
                    width: calc(100% - 1.5rem);
                    height: 4px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 0;
                }

                @include respond-below(lg) {
                    padding-left: 1rem;
                    padding-right: 1rem;

                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }

            .nav-item > a,
            .nav-item > .dropdown > .dropdown-toggle {
                color: $button-text-color;
                font-family: Nunito, sans-serif;
                font-size: 1rem;
                display: inline-block;
                transform: translateY(-50%);
                margin-top: 57px;

                @include respond-below(xl) {
                    margin-top: 53px;
                }

                @include respond-below(lg) {
                    margin-top: 50px;
                }
            }

            .nav-item > .dropdown > .dropdown-toggle {
                padding: 0 !important;
            }

            .nav-item:not(.active) > a,
            .nav-item:not(.active) .dropdown > .dropdown-toggle {
                &:hover:not(.btn),
                &:active:not(.btn) {
                    opacity: 0.8;
                }
            }

            .dropdown {
                height: 80px;
                position: relative;

                &:hover > .dropdown-menu {
                    display: block;
                }

                & > .dropdown-toggle:active {
                    pointer-events: none;
                }

                .dropdown-menu {
                    position: absolute;
                    margin-top: 0;
                }

                a.dropdown-item {
                    font-size: 0.8rem;
                    color: $primary-color;
                    padding-bottom: 0.5rem;
                    outline: initial;

                    &.active,
                    &:active {
                        color: $button-text-color;
                    }
                }
            }
        }
    }

    .landing-page-nav .headroom--unpinned {
        transform: translate3d(0px, -100%, 0px) !important;
    }

    .landing-page-nav .headroom--scrolled.headroom--pinned {
        background: $foreground-color;
        @include depth(1);

        .navbar-logo .white {
            display: none;
        }

        .navbar-logo .dark {
            display: inline-block;
        }

        .navbar-nav {
            a,
            .dropdown-toggle {
                color: $primary-color;

                &:hover,
                &:active {
                    color: $theme-color-1;
                }
            }

            .dropdown-item {
                color: $primary-color;

                &:focus {
                    color: $button-text-color;
                }
            }

            li {
                &.active::before {
                    content: ' ';
                    background-color: $theme-color-1;
                }
            }

            .btn-outline-semi-light {
                border: 1px solid rgba($theme-color-1, 0.3);
                color: $theme-color-1;

                &:hover,
                &:active {
                    border: 1px solid $theme-color-1;
                    background: $theme-color-1;
                    color: $button-text-color;
                }
            }
        }

        &.headroom--unpinned {
            background: transparent;
        }
    }

    .landing-page-nav .headroom--unfixed {
        .mobile-menu-button {
            color: $button-text-color;
        }
    }

    .section {
        margin-bottom: 150px;
        padding-top: 100px;
        padding-bottom: 100px;

        @include respond-below(md) {
            margin-bottom: 100px;
            padding-top: 60px;
        }

        &.home {
            background: url($lpBackgroundHome);
            background-repeat: no-repeat;
            background-position-y: 0;
            background-color: transparent;
            height: 1440px;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            position: relative;

            @include respond-below(xs) {
                background-position-y: -100px;
                height: 1340px;
            }
        }

        &.subpage {
            background: url($lpBackgroundSub);
            height: 690px;
            background-position-y: -350px;
            background-repeat: no-repeat;

            @include respond-below(xl) {
                background-position-y: -400px;
                height: 640px;
            }

            @include respond-below(md) {
                background-position-y: -540px;
                height: 520px;
            }

            @include respond-below(sm) {
                background-position-y: -620px;
                height: 450px;
            }
        }

        &.subpage-long {
            background: url($lpBackgroundSub);
            height: 900px;
            background-repeat: no-repeat;
            background-position-y: -180px;

            @include respond-below(md) {
                background-position-y: -220px;
                height: 830px;
            }

            @include respond-below(sm) {
                background-position-y: -310px;
                height: 760px;
            }
        }

        &.footer {
            background: url($lpBackgroundFooter);
            background-color: $foreground-color;
            background-repeat: no-repeat;
            padding-bottom: 0;
        }
    }

    .section.background {
        background: $foreground-color;
        position: relative;
        padding-top: 100px;
        padding-bottom: 100px;

        @include respond-below(md) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        &::before {
            content: ' ';
            width: 100%;
            height: 70px;
            position: absolute;
            top: -70px;
            background: url($lpBackgroundEllipsisTop);
            background-size: cover;
            background-position: center;
        }

        &::after {
            content: ' ';
            width: 100%;
            height: 70px;
            position: absolute;
            bottom: -70px;
            background: url($lpBackgroundEllipsisBottom);
            background-size: cover;
            background-position: center;
        }

        &.background-no-bottom {
            &::after {
                content: '';
                background: initial;
                width: initial;
                height: initial;
            }
        }
    }

    .background-white {
        background: $button-text-color;
    }

    .mobile-hero {
        margin-left: 50%;
        transform: translateX(-50%);
        max-width: 135%;
        margin-bottom: 3rem;
    }

    .home-row {
        padding-top: 180px;
        margin-bottom: 70px;

        @include respond-below(xl) {
            padding-top: 120px;
        }

        @include respond-below(md) {
            padding-top: 110px;
        }
    }

    .home-text {
        margin-top: 40px;

        p {
            color: $button-text-color;
            font-size: 1.1rem;

            @include respond-below(sm) {
                font-size: 0.9rem;
                line-height: 1.4rem;
            }
        }

        .display-1 {
            font-size: 3rem;
            line-height: 3.2rem;
            margin-bottom: 1.1em;
            color: $button-text-color;

            @include respond-below(md) {
                font-size: 1.8rem;
                line-height: 2.5rem;
            }

            @include respond-below(sm) {
                font-size: 1.7rem;
                line-height: 2.3rem;
            }

            @include respond-below(xs) {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }

        @include respond-below(sm) {
            margin-top: 0;
        }
    }

    .home-carousel {
        .card {
            .detail-text {
                padding: 1rem;
                margin-bottom: 0;
                min-height: 110px;

                @include respond-below(sm) {
                    padding: 0.25rem;
                    min-height: 55px;
                }
            }

            .btn-link {
                @include respond-below(sm) {
                    padding: 0.25rem;
                }
            }
        }
    }

    i.large-icon {
        font-size: 60px;
        line-height: 110px;
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#{$theme-color-2}),
            to(#{$theme-color-1})
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: initial;

        @include respond-below(sm) {
            line-height: 90px;
        }
    }

    .btn-circle {
        padding: 0;
        width: 50px;
        height: 50px;
        border-radius: 30px;
        text-align: center;
        font-size: 14px;
    }

    .btn-circle.hero-circle-button {
        position: absolute;
        bottom: 80px;
        margin-left: 10px;

        i {
            padding-top: 18px;
            display: inline-block;
        }
    }

    .btn-link {
        text-decoration: initial !important;
    }

    .newsletter-input-container {
        margin-top: 5rem;

        .input-group {
            border-radius: 50px;
            @include depth(2);
        }

        input {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            border-color: transparent !important;
            padding: 0.75rem 1.75rem 0.75rem;
            font-size: 0.9rem;
        }

        button {
            @include respond-below(sm) {
                padding: 1rem 1.5rem 0.9rem;
            }
        }
    }

    .footer {
        .footer-content {
            padding-top: 210px;

            @include respond-below(sm) {
                padding-top: 140px;
            }

            @include respond-below(xs) {
                padding-top: 80px;
            }
        }

        .footer-circle-button {
            margin-top: 80px;
            margin-right: 10px;

            @include respond-below(md) {
                margin-top: 110px;
            }

            i {
                padding-top: 15px;
                display: inline-block;
            }
        }

        .footer-logo {
            width: 140px;
            margin-bottom: 70px;
        }

        .footer-menu {
            p {
                color: $button-text-color;
                font-size: 1rem;
            }

            a {
                cursor: pointer;
                font-size: 1rem;
                color: $button-text-color;

                &:hover,
                &:focus {
                    color: darken($theme-color-1, 20%);
                }
            }

            .collapse-button {
                i {
                    font-size: 0.75rem;
                    margin-left: 5px;
                }

                &:hover,
                &:focus {
                    color: $button-text-color;
                }
            }
        }

        .separator {
            opacity: 0.3;
        }

        .copyright {
            p {
                color: $button-text-color;
            }
        }

        .social-icons {
            ul {
                margin-bottom: 0;
            }

            li {
                margin-left: 0.5rem;
                margin-right: 0.5rem;

                a {
                    color: $button-text-color;
                    font-size: 1.2rem;

                    &:hover,
                    &:active {
                        color: rgba($button-text-color, 0.7);
                    }
                }
            }
        }
    }

    .feature-image-left {
        float: right;
    }

    .feature-image-left,
    .feature-image-right {
        @include respond-below(sm) {
            margin-left: 50%;
            transform: translateX(-50%);
            max-width: 115%;
            float: initial;
        }
    }

    .heading-team {
        @include respond-below(sm) {
            text-align: center;
        }
    }

    .mobile-menu {
        text-align: center;

        img {
            width: 100px;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }

        ul {
            text-align: left;

            li {
                padding: 0.5rem 2.5rem;

                &.active {
                    a {
                        color: $theme-color-1;
                    }
                }

                a {
                    font-size: 0.9rem;
                }
            }
        }

        .dropdown {
            .dropdown-menu {
                position: static !important;
                background: initial;
                border: initial;
                padding: initial;
                margin: initial;
                transform: initial !important;
                margin-top: 0.5rem;
            }

            a.dropdown-item {
                color: $primary-color;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
                background: initial !important;

                &.active,
                &:active {
                    color: $theme-color-1;
                }
            }
        }
    }

    .side-bar {
        @include respond-below(md) {
            margin-top: 5rem;
        }

        .side-bar-content {
            margin-bottom: 5rem;
        }

        h2 {
            @include respond-below(sm) {
                margin-bottom: 1rem;
            }
        }
    }

    .listing-card-container {
        background: initial;
        box-shadow: initial;

        .listing-desc {
            line-height: 1.4;
            font-size: 1rem;
        }
    }

    .table-heading {
        box-shadow: initial;
        background: initial;
    }

    form.dark-background {
        input:-webkit-autofill {
            color: white !important;
            -webkit-text-fill-color: white !important;
        }

        .has-float-label > span,
        .has-float-label label,
        .has-top-label > span,
        .has-top-label label {
            color: rgba($button-text-color, 0.6);
        }

        .bootstrap-tagsinput,
        .form-control {
            background: transparent;
            border-color: rgba($button-text-color, 0.3);
            color: $button-text-color;

            &:focus,
            &:active {
                border-color: rgba($button-text-color, 0.6);
            }
        }
    }

    .has-float-label > span,
    .has-float-label label,
    .has-top-label > span,
    .has-top-label label {
        font-size: 64%;
    }

    .video-js.blog-video {
        width: 100%;
        height: 442px;
        background-color: $background-color;

        @include respond-below(md) {
            height: 280px;
        }

        .vjs-poster {
            background-size: cover;
        }
    }

    .video-js.side-bar-video {
        width: 100%;
        height: 280px;
        background-color: $background-color;
        overflow: hidden;

        video {
            object-fit: cover;
        }

        .vjs-poster {
            background-size: cover;
        }
    }

    .feature-icon-container {
        .detail-text {
            min-height: 100px;

            @include respond-below(md) {
                min-height: 70px;
            }
        }
    }

    .screenshots {
        .nav-tabs {
            margin-bottom: 0 !important;
            border: initial;

            .nav-link {
                cursor: pointer;
                border: initial;
                background: initial !important;
                padding-right: 40px;
                padding-left: 40px;
            }

            .nav-item.show .nav-link:before,
            .nav-link.active:before {
                top: initial;
                bottom: 0;
            }
        }
    }

    .app-image {
        width: 100%;
        @include depth(2);
        border-radius: 1.2rem;
    }

    .doc-search {
        border: 1px solid rgba($button-text-color, 0.3);
        background: transparent;
        border-radius: 40px;
        padding: 0.85rem 0.75rem 0.8rem;
        max-width: 360px;

        input {
            color: $button-text-color;
            background: transparent;
            width: 93%;
            padding: 0 0.75rem;
            outline: initial !important;
            border: initial;

            &::placeholder {
                color: $button-text-color;
                opacity: 0.7;
            }
        }

        i {
            font-size: 16px;
            color: rgba($button-text-color, 0.7);
        }
    }

    .feedback-container {
        button {
            font-size: 1.3em;
            color: $secondary-color;
            margin: 0.5rem;

            &:hover,
            &:active {
                color: $theme-color-1;
            }
        }
    }

    .page-item .page-link {
        line-height: 1.2;
    }

    .review-carousel {
        margin-top: 5rem;

        .react-siema-container > div {
            display: flex;
        }
    }
}

/*Prices*/
.price-container {
    .price-item {
        .card {
            height: 100%;
        }

        .card-body {
            text-align: center;

            @include respond-below(md) {
                text-align: left;
            }

            @include respond-below(xs) {
                text-align: center;
            }
        }

        .price-top-part {
            text-align: center;

            @include respond-below(md) {
                padding-left: 0;
                padding-right: 0;
                width: 40%;
            }

            @include respond-below(xs) {
                width: initial;
            }
        }

        .price-feature-list {
            justify-content: space-between;

            @include respond-below(md) {
                min-height: 220px;
            }

            ul {
                margin: 0 auto;
                align-self: flex-start;
                margin-bottom: 1rem;

                @include respond-below(md) {
                    margin-left: 0;
                }

                @include respond-below(xs) {
                    margin: 0 auto;
                }
            }

            a {
                @include respond-below(md) {
                    padding-left: 0;
                }

                @include respond-below(xs) {
                    padding-left: 2.6rem;
                }
            }
        }
    }
}

.feature-row {
    margin-top: 80px;

    @include respond-below(sm) {
        margin-top: 40px;
    }
}

/* Timeline */

.timeline {
    list-style: none;
    padding: 10px 0;
    position: relative;
    font-weight: 300;

    &:before {
        top: 0;
        bottom: 0;
        position: absolute;
        content: ' ';
        width: 2px;
        background: $foreground-color;
        left: 50%;
        margin-left: -1.5px;

        @include respond-below(md) {
            left: 45px;
        }
    }

    & > li {
        margin-bottom: 120px;
        position: relative;
        width: 50%;
        float: left;
        clear: left;

        @include respond-below(md) {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    & > li:before,
    & > li:after {
        content: ' ';
        display: table;
    }

    & > li:after {
        clear: both;
    }

    & > li:before,
    & > li:after {
        content: ' ';
        display: table;
    }

    & > li:after {
        clear: both;
    }

    & > li > .timeline-panel {
        width: calc(100% - 60px);
        float: left;
        position: relative;

        @include respond-below(md) {
            float: right;
            width: calc(100% - 120px);
            margin-right: 15px;
        }
    }

    & > li > .timeline-panel:after {
        position: absolute;
        top: 16px;
        right: -7px;
        display: inline-block;
        border-top: 7px solid transparent;
        border-left: 7px solid $foreground-color;
        border-right: 0 solid $foreground-color;
        border-bottom: 7px solid transparent;
        content: ' ';

        @include respond-below(md) {
            border-left-width: 0;
            border-right-width: 7px;
            left: -7px;
            right: auto;
        }
    }

    & > li.timeline-inverted > .timeline-panel {
        float: right;
    }

    & > li.timeline-inverted > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 7px;
        left: -7px;
        right: auto;
    }

    & > li.timeline-inverted > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 7px;
        left: -7px;
        right: auto;
    }

    .timeline-badge > a {
        color: $foreground-color !important;
    }

    & > li.timeline-inverted {
        float: right;
        clear: right;
    }

    & > li:nth-child(2) {
        margin-top: 120px;

        @include respond-below(md) {
            margin-top: 0;
        }
    }

    .no-float {
        float: none !important;
    }

    & > li > .timeline-badge {
        color: $foreground-color;
        width: 60px;
        height: 60px;
        font-size: 14px;
        text-align: center;
        position: absolute;
        background-color: $theme-color-1;
        right: -30px;
        border-radius: 30px;
        padding-top: 18px;

        @include respond-below(md) {
            left: 15px;
            right: initial;
        }
    }

    & > li.timeline-inverted > .timeline-badge {
        right: initial;
        left: -30px;

        @include respond-below(md) {
            left: 15px;
        }
    }
}

/* width */
.wadsworth-body::-webkit-scrollbar {
    width: 20px;
}

/* Track */
.wadsworth-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.wadsworth-body::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.wadsworth-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.wadsworth-content {
    margin-top: -35px;
}

.wadsworth-content .rjsf .form-group button.btn.btn-primary {
    width: 100%;
}

.wadsworth input::-webkit-outer-spin-button,
.wadsworth input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.google-visualization-charteditor-dialog {
    max-width: 1000px !important;
    pointer-events: all !important;
    width: calc(90%) !important;
    height: calc(70%) !important;
}

.google-visualization-charteditor-dialog .goog-flat-menu-button,
.google-visualization-clickeditor-bubble .goog-flat-menu-button,
.google-visualization-charteditor-custom-panel .goog-flat-menu-button {
    height: 28px !important;
}

.modal-dialog.google-visualization-charteditor-dialog {
    position: absolute !important;
    padding: 30px 42px !important;
}

.tri12 {
    font-family: Triumvirate;
    font-size: 12pt;
}

.tri14 {
    font-family: Triumvirate;
    font-size: 14pt;
}

.tri16 {
    font-family: Triumvirate;
    font-size: 16pt;
}

.tri18 {
    font-family: Triumvirate;
    font-size: 18pt;
}

.tri20 {
    font-family: Triumvirate;
    font-size: 20pt;
}

.tri24 {
    font-family: Triumvirate;
    font-size: 24pt;
    letter-spacing: -1pt;
}

.tri30 {
    font-family: Triumvirate;
    font-size: 30pt;
    letter-spacing: -1pt;
}

.tri32 {
    font-family: Triumvirate;
    font-size: 32pt;
    letter-spacing: -2pt;
}

.tri36 {
    font-family: Triumvirate;
    font-size: 36pt;
}

.aikenmanualentryqtyinput input::-webkit-outer-spin-button,
.aikenmanualentryqtyinput input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
