@media all {
    .page-break {
        display: block !important;
    }
}

@media print {
    * {
        float: none !important;
        overflow: visible !important;
    }

    .modal {
        visibility: visible !important;
        /**Remove scrollbar for printing.**/
        overflow: visible !important;
        position: relative !important;
    }
    .modal-dialog {
        visibility: visible !important;
        /**Remove scrollbar for printing.**/
        overflow: visible !important;
    }
    .modal-content {
        display: block !important;
        visibility: visible !important;
        /**Remove scrollbar for printing.**/
        overflow: visible !important;
    }

    .modal-backdrop {
        background: #ffffff !important;
    }

    body,
    html {
        background: #ffffff !important;
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        font-size: 18pt !important;
    }

    .page-break {
        margin-top: 1rem;
        float: none !important;
        break-before: always !important;
        page-break-before: always !important;
        break-inside: avoid !important;
        page-break-inside: avoid !important;
    }
}

/** Setting margins */
@page {
    margin: 2cm;
    size: auto;
    font-size: 18pt !important;
}

/* Or: */
@page :left {
    margin: 1cm;
}

@page :right {
    margin: 1cm;
}

/* The first page of a print can be manipulated as well */
@page :first {
    margin: 1cm 2cm;
}
