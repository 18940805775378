.kb-button {
    position: fixed;
    bottom: 0px;
    right: 0%;
    padding: 8px;
    opacity: 0.75;
    z-index: 100000;
}

.kb-button-mobile {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100px;
    padding: 8px;
    opacity: 0.75;
    z-index: 100000;
}
