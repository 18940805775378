.srd-diagram {
    position: relative;
    flex-grow: 1;
    display: flex;
    cursor: move;
    overflow: hidden;
    height: 100%;
}

.srd-diagram__selector {
    position: absolute;
    background-color: rgba(0, 192, 255, 0.2);
    border: solid 2px #00c0ff;
}

.srd-link-layer {
    position: absolute;
    height: 100%;
    width: 100%;
    transform-origin: 0 0;
    overflow: visible !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.srd-node-layer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
}

.srd-node {
    position: absolute;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: move;
    pointer-events: all;
    width: 175px;
}

.srd-node--selected > * {
    border-color: #00c0ff !important;
}

.srd-port {
    width: 15px;
    height: 15px;
    background: rgba(255, 255, 255, 0.1);
}

.srd-port:hover,
.srd-port.selected {
    background: #c0ff00;
}

.srd-default-node {
    background-color: #1e1e1e;
    border-radius: 5px;
    font-family: sans-serif;
    color: white;
    border: solid 2px black;
    overflow: visible;
    font-size: 11px;
}

.srd-default-node__title {
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    white-space: nowrap;
}

.srd-default-node__title > * {
    align-self: center;
}

.srd-default-node__title .fa {
    padding: 5px;
    opacity: 0.2;
    cursor: pointer;
}

.srd-default-node__title .fa:hover {
    opacity: 1;
}

.srd-default-node__name {
    flex-grow: 1;
    padding: 5px 5px;
}

.srd-default-node__ports {
    display: flex;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
}

.srd-default-node__in,
.srd-default-node__out {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.srd-default-port {
    display: flex;
    margin-top: 1px;
}

.srd-default-port > * {
    align-self: center;
}

.srd-default-port__name {
    padding: 0 5px;
}

.srd-default-port--out {
    justify-content: flex-end;
}

.srd-default-port--out .srd-default-port__name {
    justify-content: flex-end;
    text-align: right;
}

.srd-default-label {
    background: rgba(70, 70, 70, 0.8);
    border: 1px solid #333;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: smaller;
    padding: 5px;
}

@keyframes dash {
    from {
        stroke-dashoffset: 24;
    }

    to {
        stroke-dashoffset: 0;
    }
}

.srd-default-link path {
    fill: none;
    stroke: #00c0ff !important;
    stroke-dasharray: 10, 2;
    animation: dash 1s linear infinite;
    pointer-events: all;
}

.srd-default-link--path-selected {
    stroke: #00c0ff !important;
    stroke-dasharray: 10, 2;
    animation: dash 1s linear infinite;
}

.srd-default-link__label {
    pointer-events: none;
}

.srd-default-link__label > div {
    display: inline-block;
    position: absolute;
}

.srd-default-link__point {
    fill: rgba(255, 255, 255, 0.5);
}

.srd-default-link--point-selected {
    fill: #00c0ff;
}
