.rt-tr-group:hover {
    background-color: #2a93d5;
    color: #fff;
    border-radius: 0px;
    cursor: pointer;
}

.pagination-bottom > nav {
    display: flex;
    justify-content: center;
}