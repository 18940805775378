@media all {
    body {
        background-color: #ffffff !important;
    }
}

@media print {
    body,
    html,
    .modal-backdrop {
        background: #ffffff !important;
    }
}

/* Setting content width, unsetting floats and margins */
/* Attention: the classes and IDs vary from theme to theme. Thus, set own classes here */
#content,
#page {
    width: 100%;
    margin: 0;
    float: none;
}

/** Setting margins */
@page {
    margin: 2cm;
}

/* Or: */
@page :left {
    margin: 1cm;
}

@page :right {
    margin: 1cm;
}

/* The first page of a print can be manipulated as well */
@page :first {
    margin: 1cm 2cm;
}
