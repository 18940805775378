.tv-display-clock {
    position: fixed;
    bottom: 0px;
    right: 0px;
    background-color: #fff;
    padding: 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    opacity: 0.75;
}
