$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color: white;
$input-background: white;

$dark-btn-background: #131113;
$light-btn-background: #ececec;

$button-text-color: #fff;

$theme-color-1: #419650;
$theme-color-2: #0794bc;
$theme-color-3: #849b65;
$theme-color-4: #494d43;
$theme-color-5: #d1c19a;
$theme-color-6: #7c8174;

$primary-color: #212121;
$secondary-color: #8f8f8f;
$muted-color: #909090;

$gradient-color-1: #7baa39;
$gradient-color-2: #576a3d;
$gradient-color-3: #73904c;

$shadowOffsetsTop: 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom: 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$logoPath: '../../img/L2L_ConnectLogo_greenblack.svg';
$largeLogo: '../../img/L2L_Connect_logo_large.svg';
$logoPathMobile: '../../img/L2L_ConnectLogo_greenblack.svg';

$lpBackgroundHome: '';
$lpBackgroundSub: '';
$lpBackgroundFooter: '';
$lpBackgroundEllipsisBottom: '';
$lpBackgroundEllipsisTop: '';

$lpLogoPath: $logoPath;
$lpLogoPathPinned: $logoPath;

@import '../_mixins.scss';
@import '../_gogo.style.scss';
