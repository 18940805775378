.flash_hmi {
    animation: hmi_flasher 3s linear infinite;
}

@keyframes hmi_flasher {
    50% {
        opacity: 0;
    }
}

.hmi .react-select__menu-list::-webkit-scrollbar {
    width: 30px;
}

.hmi .react-select__menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.hmi .react-select__menu-list::-webkit-scrollbar-thumb {
    background: #888;
}
.hmi .react-select__menu-list:-webkit-scrollbar-thumb:hover {
    background: #555;
}
