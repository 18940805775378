.round-check {
    position: relative;
}

.round-check label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.round-check label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round-check input[type='checkbox'] {
    visibility: hidden;
}

.round-check input[type='checkbox']:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.round-check input[type='checkbox']:checked + label:after {
    opacity: 1;
}

.round-x {
    position: relative;
}

.round-x label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.round-x label:after {
    content: 'X';
    font-weight: 900;
    color: #fff;
    height: 6px;
    left: 9px;
    opacity: 0;
    position: absolute;
    top: 5px;
    width: 12px;
}

.round-x input[type='checkbox'] {
    visibility: hidden;
}

.round-x input[type='checkbox']:checked + label {
    background-color: #ff0000;
    border-color: #ff0000;
}

.round-x input[type='checkbox']:checked + label:after {
    opacity: 1;
}
