.poi {
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    height: 20px;
    width: 20px;
    transform: scale(1);
    animation: pulse-black 5s infinite;
}

@keyframes pulse-black {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
