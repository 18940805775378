.circle-background,
.circle-progress {
    fill: none;
}

.circle-background {
    stroke: #ddd;
}

.circle-progress {
    stroke: #b69329;
    stroke-linecap: square;
    stroke-linejoin: round;
}

.circle-text {
    font-size: 2em;
    font-weight: bold;
    fill: #fff;
}
