.blink {
    animation: blinker 2s linear infinite;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: #dc3545;
    padding: 20px;
    opacity: 0.75;
    text-align: center;
    z-index: 4;
}

@keyframes blinker {
    50% {
        background-color: transparent;
    }
}
