.flash {
    background-color: 'rgb(231, 76, 60)';
    animation: bg-red-radar infinite 2s linear;
}

@keyframes bg-red-radar {
    from {
        background-color: 'rgb(231, 76, 60)';
    }
    to {
        background-color: #555555;
    }
}
